import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AfterLoginComponent} from '../../after-login/after-login.component';
import {AuthGuard} from '../../Guards/auth.guard';
import {HomeComponent} from './home/home.component';
import {EnrollNewStudentComponent} from './enroll-new-student/enroll-new-student.component';
import {StudentDetailsComponent} from './student-details/student-details.component';
import {StudentListClassroomComponent} from './student-list-classroom/student-list-classroom.component';

const routes: Routes = [{
  path: 'admin/students',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent },
    { path: 'enroll', component: EnrollNewStudentComponent },
    { path: 'details/:registrationNumber', component: StudentDetailsComponent },
    { path: 'list/:classroomID', component: StudentListClassroomComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule { }
