import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})
export class EmployeeDetailsComponent implements OnInit {
  employeeID: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.employeeID = parseInt(
      this.route.snapshot.paramMap.get('employeeID')
    );
  }

}
