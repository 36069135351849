import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Exam} from '../../../Models/Exam/exam.model';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExamService {

  constructor(
    private http: HttpClient
  ) { }

  all(): Observable<Exam[]> {
    return this.http.get<Exam[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/exam/all'
    ).pipe(
      map(
        (exams: any) => exams.data as Exam[]
      )
    );
  }

  update(examId: number, title: string): Observable<any> {
    return this.http.put<any>(
      GlobalValues.BASE_ENDPOINT_URL +
      "/exam/"+examId,
      {
        'exam_title' : title
      }
    );
  }

  delete(examId): Observable<any> {
    return this.http.delete(
      GlobalValues.BASE_ENDPOINT_URL +
      "/exam/"+examId
    );
  }

  create(examTitle: string): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      "/exam", {
        'exam_title': examTitle
      }
    );
  }
}
