import {Component, Input, OnInit} from '@angular/core';
import {Classroom} from '../../../../Models/Classrooms/Classroom';
import {EmployeeService} from '../../services/employee.service';

@Component({
  selector: 'app-class-room',
  templateUrl: './class-room.component.html',
  styleUrls: ['./class-room.component.css']
})
export class ClassRoomComponent implements OnInit {
  @Input() employeeID: number;
  classrooms: Classroom[];

  constructor(private _employee: EmployeeService) { }

  ngOnInit() {
    this.updateClassRooms();
  }

  updateClassRooms() {
    this._employee.classrooms(this.employeeID).subscribe(
      classrooms => this.classrooms = classrooms
    );
  }
}
