export class Subject {
  id: number;
  subject_class_id: number;
  session_id: number;
  subject_id: number;
  class_room_id: number;
  teacher_id: number;
  status: boolean;
  title: string;
  category_id: number;
  school_id: number;
  category_title: number;
}
