import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalValues} from '../../../../globals';
import {Observable} from 'rxjs';
import {StudentPayment} from '../Models/StudentPayment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(
    private http: HttpClient,
  ) { }

  addStudentTransaction(regNo, accountID, amount, comment): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/transaction',
      {
        registration_number : regNo,
        account_id : accountID,
        amount,
        comment
      });
  }
  payAmount(transactionID, amount, comment): Observable<any> {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/transaction/' + transactionID + '/pay',
      {
        amount,
        comment
      });
  }
  getTransactionPayments(accountID, transactionID): Observable<StudentPayment[]> {
    return this.http.get<StudentPayment[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/transaction/payments/' + accountID + '/' + transactionID);
  }
  delete(transactionID) {
    return this.http.delete<any>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/transaction/' + transactionID);
  }

  // Employees Methods ...

  addEmployeeListTransactions(accountID: number, comment: string, employees): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employees/finance/transaction/all', {
        account_id: accountID,
        comment,
        employees
      }
    );
  }
  getEmployeeTransactions(employeeID: number) {
  }

  addEmployeeTransaction(
    employeeID: number, accountID: number, amount: number, comment: string
  ): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employees/finance/transaction/' + employeeID,
      {
        account_id : accountID,
        amount,
        comment
      });
  }

  payEmployeeAmount(transactionID, amount, comment): Observable<any> {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employees/finance/transaction/' + transactionID,
      {
        amount,
        comment
      });
  }

  deleteEmployeeTx(transactionID) {
    return this.http.delete<any>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employees/finance/transaction/' + transactionID);
  }
}
