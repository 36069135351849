import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResultService} from '../../services/result.service';
import {Classroom} from '../../../../Models/Classrooms/Classroom';
import {Exam} from '../../../../Models/Exam/exam.model';
import {AcademicSession} from '../../../sessions/models/AcademicSession';
import {SessionExam} from '../../../../Models/Exam/SessionExam';
import {Subject} from '../../../../Models/Subjects/Subject';
import {SubjectService} from '../../../subjects/services/subject.service';
import {map} from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: 'app-subject-result',
  templateUrl: './subject-result.component.html',
  styleUrls: ['./subject-result.component.css']
})
export class SubjectResultComponent implements OnInit {

  classSubjectID: number;
  sessionExamID: number;

  classroom: Classroom;
  exam: Exam;
  session: AcademicSession;
  sessionExam: SessionExam;
  subject: Subject;
  examDate: string;
  students: any;
  result: any;
  totalMarks: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resultService: ResultService,
    private subjectService: SubjectService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.classSubjectID = params.class_subject_id;
      this.sessionExamID = params.session_exam_id;
    });
    this.getResult();
  }

  getResult() {
    this.resultService.getResult(this.sessionExamID, this.classSubjectID)
      .subscribe(resp => {
        this.classroom = resp.classroom;
        this.exam = resp.exam;
        this.sessionExam = resp.session_exam;
        this.session = resp.session;
        this.result = resp.result;
        this.examDate = resp.exam_date;
        this.totalMarks = resp.total_marks;
        this.getStudents();
      }, error => {
        if (error.status == 400) {
          Swal.fire('Result not found!',
            'Input either invalid or result not updated.',
            'error')
            .then(() => {
              this.router.navigate(['/admin/result']);
            });
        }
      });

    this.subjectService.getSubject(this.classSubjectID)
      .subscribe(subject => this.subject = subject);
  }
  getStudents() {
    this.resultService.getStudentList(this.sessionExamID, this.classSubjectID)
      .subscribe(resp => {
        map(this.students = resp);
        for (const student of this.students) {
          for (const result of this.result) {
            if (student.student_id == result.student_id) {
              student.result = result;
              if (result.result.exam_date) {
                this.examDate = result.result.exam_date;
              }
              break;
            }
          }
        }
        Swal.close();
      });
  }
}
