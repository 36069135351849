import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Category} from '../../../Models/Subjects/Category';
import {BaseSubject} from '../../../Models/Subjects/BaseSubject';
import {EmployeeListItem} from '../../employees/models/employee-list-item';
import {SubjectService} from '../services/subject.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-new-subject',
  templateUrl: './new-subject.component.html',
  styleUrls: ['./new-subject.component.css']
})
export class NewSubjectComponent implements OnInit {
  @Input() categories: Category[];
  @Input() baseSubjects: BaseSubject[];
  @Input() employees: EmployeeListItem[];
  @Input() classroomID: number;
  @Output() newSubjectAdded = new EventEmitter();
  //Models
  mCategory: number;
  mBaseSubject: number;
  mTeacher: number;
  //Error Messages
  eCategory:string = null;
  eBaseSubject:string = null;
  eTeacher:string = null;
  eMain: string = null;

  constructor(
    private subjectService: SubjectService
  ) {}
  newsubjectstate: boolean = false;

  ngOnInit() {
  }

  toggleNewSubjectFlag() {
    this.newsubjectstate = !this.newsubjectstate;
  }

  resetBaseSubjectValue() {
    this.mBaseSubject = null;
  }

  addSubject() {
    this.eCategory = null;
    this.eBaseSubject = null;
    this.eTeacher = null;
    this.eMain = null;
    if(!this.mCategory) {
      this.eCategory = "Select a category.";
      return;
    }
    if (!this.mBaseSubject) {
      this.eBaseSubject = "Select a Base Subject.";
      return;
    }
    if(!this.mTeacher) {
      this.eTeacher = "Select a teacher.";
      return;
    }

    this.subjectService.addSubject(
      this.classroomID,
      this.mBaseSubject,
      this.mTeacher
    ).subscribe(
      resp => {
        Swal.fire(resp,"",'success');
        this.toggleNewSubjectFlag();
        this.newSubjectAdded.emit();
      }, err => {
        this.eMain = err.error.error;
      }
    );
  }

}
