import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmployeeRoutingModule} from './employee-routing.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtAppInterceptorService} from '../../Guards/services/jwt-app-interceptor.service';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeItemComponent } from './employee-list/employee-item/employee-item.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { BasicInfoComponent } from './employee-details/basic-info/basic-info.component';
import { ClassRoomComponent } from './employee-details/class-room/class-room.component';
import { SubjectComponent } from './employee-details/subject/subject.component';
import { ContactDetailsComponent } from './employee-details/contact-details/contact-details.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import {NgbDatepickerModule, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ProfileImageComponent} from "../student/enroll-new-student/profile-image/profile-image.component";
import {StudentModule} from "../student/student.module";
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  declarations: [
    EmployeeListComponent,
    EmployeeItemComponent,
    EmployeeDetailsComponent,
    BasicInfoComponent,
    ClassRoomComponent,
    SubjectComponent,
    ContactDetailsComponent,
    AddEmployeeComponent
  ],
  imports: [
    CommonModule,
    EmployeeRoutingModule,
    SweetAlert2Module.forRoot(),
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    StudentModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAppInterceptorService,
      multi: true
    },
  ]
})
export class EmployeesModule { }
