import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from '../../Guards/auth.guard';
import {HomeComponent} from './home/home.component';
import {AfterLoginComponent} from '../../after-login/after-login.component';
import {ClassroomComponent} from './home/classroom/classroom.component';
import {ClassroomListComponent} from './classroom-list/classroom-list.component';
import {DailyLessonsNotificationComponent} from "./daily-lessons-notification/daily-lessons-notification.component";

const routes: Routes = [
  {
    path: 'admin/classrooms',
    component: AfterLoginComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: ':id', component: ClassroomListComponent },
      { path: 'notify/:id', component: DailyLessonsNotificationComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassroomsRoutingModule { }
