import {EmployeeTransactionPayment} from './EmployeeTransactionPayment';

export class EmployeeTransaction {
  id: number;
  account_id: number;
  employee_id: number;
  total_amount: number;
  amount_paid: number;
  time_payment: string;
  status: string;
  comment: string;
  created_at: string;
  updated_at: string;
  transaction_payments: EmployeeTransactionPayment[];

  constructor() {
    this.transaction_payments = [];
  }
}
