import {Component, OnInit } from '@angular/core';
import {SessionExam} from '../../../../Models/Exam/SessionExam';
import {AcademicSession} from '../../../sessions/models/AcademicSession';
import {SessionExamService} from '../../services/session-exam.service';

@Component({
  selector: 'app-sessionexam-home',
  templateUrl: './sessionexam-home.component.html',
  styleUrls: ['./sessionexam-home.component.css']
})
export class SessionexamHomeComponent implements OnInit {
  sessionExams: SessionExam[];
  session: AcademicSession;
  addExamStatus: boolean;
  examIDs: number[] = [];

  constructor(
    private sessionExamService: SessionExamService
  ) { }

  ngOnInit() {
    this.addExamStatus = false;
    this.getSessionExams();
  }


  getSessionExams() {
    this.sessionExamService.all().subscribe(
      resp => {
        this.sessionExams = resp.data.session_exams;
        this.session = resp.data.session;
        this.examIDs = [];
        for(let examSession of this.sessionExams) {
          this.examIDs.push(examSession.exam.id);
        }
      }
    );
  }

  toggleAddExamStatus() {
    this.addExamStatus = !this.addExamStatus;
  }

  sessionExamDeleted(examID: number) {
    let index = this.examIDs.indexOf(examID);
    delete this.examIDs[index];
  }
}
