import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentTransaction} from '../../../Models/StudentTransaction';
import {Account} from '../../../Models/Account';
import {TransactionService} from '../../../Services/transaction.service';
import {StudentPayment} from '../../../Models/StudentPayment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-student-transaction-record',
  templateUrl: './student-transaction-record.component.html',
  styleUrls: ['./student-transaction-record.component.css']
})
export class StudentTransactionRecordComponent implements OnInit {
  @Input() transaction: StudentTransaction;
  @Input() account: Account;
  @Output() deleted = new EventEmitter<any>();
  payments: StudentPayment[] = [];

  receivePaymentStatus = false;
  paymentDetailsStatus = false;
  mAmount: number;
  mAmountErr = false;
  mAmountErrMsg: string;
  mComment: string;
  constructor(
    private transactionService: TransactionService
  ) {
  }

  ngOnInit() {
    this.getPaymentDetails();
    this.resetValues();
  }

  resetValues() {
    this.mAmount = this.transaction.amount - this.transaction.amount_recieved;
  }

  toggleReceivePayment() {
    this.receivePaymentStatus = !this.receivePaymentStatus;
  }

  togglePaymentDetailsStatus() {
    this.paymentDetailsStatus = !this.paymentDetailsStatus;
  }

  payAmount() {
    this.mAmountErr = false;
    this.mAmountErrMsg = '';
    if (this.mAmount < 1 ||
      this.mAmount > (this.transaction.amount - this.transaction.amount_recieved)
    ) {
      this.mAmountErr = true;
      this.mAmountErrMsg = 'Amount must not be less than 1 and greater than ' +
        (this.transaction.amount - this.transaction.amount_recieved);
      Swal.fire('Error', this.mAmountErrMsg, 'error');
      return;
    }
    Swal.showLoading();
    this.transactionService.payAmount(this.transaction.id, this.mAmount, this.mComment)
      .subscribe(resp => {
        this.transaction = resp.data.transaction;
        this.payments.push(resp.data.payment);
        Swal.fire('Transaction Successful', '', 'success');
        this.receivePaymentStatus = false;
        this.resetValues();
      }, error => {
        Swal.fire('Error', error.error.message, 'error');
      });
  }

  getPaymentDetails() {
    this.transactionService.getTransactionPayments(this.account.id, this.transaction.id)
      .subscribe(payments => this.payments = payments
      , error => this.payments = []);
  }

  deleteTransaction() {
    Swal.showLoading();
    this.transactionService.delete(this.transaction.id)
      .subscribe(
        resp => {
          console.log('Resp', resp);
          this.deleted.emit(this.transaction.id);
          Swal.fire(resp.message, null, 'success');
        }, error => {
          let errMessage = 'Unknown Error';
          if (error.error.message) {
            errMessage = error.error.message;
          } else {
            errMessage = error.statusText;
          }

          Swal.fire(errMessage,
            '',
            'error');
        }
      );
  }

}
