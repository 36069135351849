import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentAttendanceRoutingModule } from './student-attendance-routing.module';
import { HomeComponent } from './home/home.component';
import {FormsModule} from "@angular/forms";
import { ClassroomAttendanceInputComponent } from './classroom-attendance/classroom-attendance-input/classroom-attendance-input.component';
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import { ClassroomAttendanceViewSingleComponent } from './classroom-attendance/classroom-attendance-view-single/classroom-attendance-view-single.component';
import { ClassroomAttendanceViewRangeComponent } from './classroom-attendance/classroom-attendance-view-range/classroom-attendance-view-range.component';
import { AttendanceDayComponent } from './classroom-attendance/classroom-attendance-view-range/attendance-day/attendance-day.component';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";

@NgModule({
  declarations: [HomeComponent, ClassroomAttendanceInputComponent, ClassroomAttendanceViewSingleComponent, ClassroomAttendanceViewRangeComponent, AttendanceDayComponent],
    imports: [
        CommonModule,
        StudentAttendanceRoutingModule,
        FormsModule,
        NgbDatepickerModule,
        SweetAlert2Module
    ]
})
export class StudentAttendanceModule { }
