import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentRoutingModule } from './student-routing.module';
import { HomeComponent } from './home/home.component';
import { EnrollNewStudentComponent } from './enroll-new-student/enroll-new-student.component';
import {FormsModule} from '@angular/forms';
import { ProfileImageComponent } from './enroll-new-student/profile-image/profile-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgxMaskModule} from 'ngx-mask';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { BasicInfoComponent } from './student-details/basic-info/basic-info.component';
import { EnrollmentInfoComponent } from './student-details/enrollment-info/enrollment-info.component';
import { AddressComponentComponent } from './student-details/address-component/address-component.component';
import { SearchByRegnoComponent } from './search-by-regno/search-by-regno.component';
import { ClassRoomSelectComponent } from './class-room-select/class-room-select.component';
import { StudentListClassroomComponent } from './student-list-classroom/student-list-classroom.component';

@NgModule({
  declarations: [HomeComponent, EnrollNewStudentComponent, ProfileImageComponent, StudentDetailsComponent, BasicInfoComponent, EnrollmentInfoComponent, AddressComponentComponent, SearchByRegnoComponent, ClassRoomSelectComponent, StudentListClassroomComponent],
  imports: [
    CommonModule,
    StudentRoutingModule,
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    ImageCropperModule,
    SweetAlert2Module,
    NgxMaskModule.forRoot()
  ],
  exports: [
    ProfileImageComponent
  ]
})
export class StudentModule { }
