import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GlobalValues} from '../../../../globals';

@Injectable({
  providedIn: 'root'
})
export class DailyLessonNotificationService {

  constructor(
    private http: HttpClient
  ) { }

  updateDailyLessons(classroomID, lessonDate, lessonData): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/dailylessons/' + classroomID,
      {
        'lesson_date' : lessonDate,
        'lesson_data' : lessonData
      });
  }

}
