import {Component, Input, OnInit} from '@angular/core';
import {ContactDetails} from '../../../../Models/User/Contact';
import {EmployeeService} from '../../services/employee.service';
import {Address} from '../../../../Models/User/Profile/Address/Address';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent implements OnInit {
  @Input() employeeID: number;
  contact: ContactDetails;

  constructor(private _employee: EmployeeService) { }

  ngOnInit() {
    this.updateContactDetails();
  }

  updateContactDetails() {
    this._employee.contact(this.employeeID).subscribe(
      contact => {
        this.contact = contact;
      }
    );
  }

  getAddress(addr: Address): string {
    let address = "";
    address += addr.line1 + ", " + addr.line2 + ", " + addr.zip_code;
    address += ", City " + addr.city.name + ", ";
    address += addr.city.district.name + ", ";
    address += addr.city.district.state.name + ", ";
    address += addr.city.district.state.country.name+".";
    return address;
  }

}
