import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Grade} from '../../../Models/Classrooms/Grade';
import {GradeService} from '../../grade/services/grade.service';
import {EmployeeService} from '../../employees/services/employee.service';
import {EmployeeListItem} from '../../employees/models/employee-list-item';
import {ClassroomService} from '../services/classroom.service';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-new-classroom',
  templateUrl: './new-classroom.component.html',
  styleUrls: ['./new-classroom.component.css']
})
export class NewClassroomComponent implements OnInit {

  @Output() cancelNewClassroom = new EventEmitter<boolean>();
  @Output() updateClassrooms = new EventEmitter();
  @ViewChild('SwalClassroomAdded') private classroomAddedSwal: SwalComponent;

  constructor(
    private gradeService: GradeService,
    private employeeService: EmployeeService,
    private classroomService: ClassroomService
  ) { }

  ngOnInit() {
    this.getGrades();
    this.grade = null;
  }
  grades: Grade[];
  @Input() teachers;
  respSuccessMessage: string = null;

  title: string;
  titleErrorMsg: string = null;

  grade: number;
  gradeErrorMsg: string = null;

  classTeacher: number;
  classTeacherErrorMsg: string = null;

  respError: string = null;

  getGrades() {
    this.gradeService.getGrades().subscribe(
      grades => this.grades = grades
    );
  }

  cancelClassroom() {
    this.cancelNewClassroom.emit(false);
  }

  addClassroom() {
    this.gradeErrorMsg = null;
    this.classTeacherErrorMsg = null;
    this.titleErrorMsg = null;
    this.respError = null;

    if(!this.title || this.title.trim().length < 2) {
      this.titleErrorMsg = "Title must be at least 2 characters long.";
      return;
    }

    if(!this.grade) {
      this.gradeErrorMsg = "Select a valid grade.";
      return;
    }
    if(!this.classTeacher) {
      this.classTeacherErrorMsg = "Select a teacher.";
      return;
    }
    // Add new Classroom
    this.classroomService.addClassroom(this.title, this.grade, this.classTeacher)
      .subscribe(
        resp => {
          this.respSuccessMessage = resp.msg;
          this.updateClassrooms.emit(true);
          this.cancelNewClassroom.emit(true);
          this.classroomAddedSwal.show();

        }, err => {
          this.respError = err.error.error;
        }
      );

  }

}
