import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Exam} from '../../../../Models/Exam/exam.model';
import {ExamService} from '../../services/exam.service';
import {SessionExamService} from '../../services/session-exam.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-session-exam',
  templateUrl: './add-session-exam.component.html',
  styleUrls: ['./add-session-exam.component.css']
})
export class AddSessionExamComponent implements OnInit {
  mExamID: number;
  exams: Exam[];
  @Input() examIDs: number[];
  examErrorMsg: string = null;
  @Output() newExamAdded = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(
    private examService: ExamService,
    private sessionExamService: SessionExamService
  ) { }

  ngOnInit() {
    this.getExams();
  }

  getExams() {
    this.examService.all().subscribe(
      exams => this.exams = exams
    );
  }

  addSessionExam() {
    this.examErrorMsg = null;
    if(!this.mExamID) {
      this.examErrorMsg = "Select an exam to continue.";
      return;
    }
    Swal.showLoading();
    this.sessionExamService.add(this.mExamID)
      .subscribe(resp => {
        this.newExamAdded.emit();
        Swal.fire({
          title: resp.message,
          text: null,
          type: 'success'
        });
      }, error1 => {
        console.log("Error1",error1);
        let errorMsg = "";
        if(error1.error.error.exam_id) {
          errorMsg = error1.error.error.exam_id;
        } else if(error1.error.error) {
          errorMsg = error1.error.error;
        } else {
          errorMsg = error1.status + ": " + error1.statusText;
        }
        Swal.fire({
          title: errorMsg,
          text: null,
          type: 'error'
        });
      });
  }
}
