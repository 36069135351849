import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeAttendanceRoutingModule } from './employee-attendance-routing.module';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    EmployeeAttendanceRoutingModule
  ]
})
export class EmployeeAttendanceModule { }
