import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfterLoginComponent } from '../../after-login/after-login.component';
import { AuthGuard } from '../../Guards/auth.guard';
import {ListComponent} from './list/list.component';


const sessionRoutes: Routes = [
  {
    'path' : 'admin/session',
    component: AfterLoginComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ListComponent }
    ]
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(sessionRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SessionsRoutingModule { }
