import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AfterLoginComponent} from "../../after-login/after-login.component";
import {AuthGuard} from "../../Guards/auth.guard";
import {HomeComponent} from "./home/home.component";

const routes: Routes = [{
  path: 'admin/attendance/employees',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeAttendanceRoutingModule { }
