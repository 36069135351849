export class Classroom {
  id: number;
  school_id: number;
  grade_id: number;
  session_id: number;
  teacher_id: number;
  title: string;
  created_at: string;
  updated_at: string;
}
