import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-classroom-charge',
  templateUrl: './classroom-charge.component.html',
  styleUrls: ['./classroom-charge.component.css']
})
export class ClassroomChargeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
