import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Classroom} from '../../../../Models/Classrooms/Classroom';
import { Grade} from '../../../../Models/Classrooms/Grade';
import {EmployeeService} from '../../../employees/services/employee.service';
import {EmployeeInfo} from '../../../employees/models/employee-info';
import {EmployeeListItem} from '../../../employees/models/employee-list-item';
import {ClassroomService} from '../../services/classroom.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.css']
})
export class ClassroomComponent implements OnInit {
  @Input() classroom: Classroom;
  @Input() grade: Grade;
  @Input() grades: Grade[];
  @Input() teachers: EmployeeListItem[];

  @Output() deleteClassroom = new EventEmitter();
  // @Input()
  employee: EmployeeInfo;
  editStatus: boolean = false;
  // Edit Variables
  title: string;
  gradeId: number;
  teacherId: number;

  //Error Msgs
  gradeErrorMsg: string = null;
  classTeacherErrorMsg: string = null;
  titleErrorMsg: string = null;
  respError: string = null;
  constructor(
    private employeeService: EmployeeService,
    private classroomService: ClassroomService
  ) { }

  ngOnInit() {
    this.getEmployeeInfo(this.classroom.teacher_id);
    this.gradeId = this.grade.id;
    this.title = this.classroom.title;
    this.teacherId = this.classroom.teacher_id;
  }

  getEmployeeInfo(employeeUserID: number) {
    this.employeeService.getInfo(employeeUserID).subscribe(
        info => this.employee = info
    );
  }

  updateEdit() {
    this.editStatus = !this.editStatus;
  }

  cancelEdit() {
    this.title = this.classroom.title;
    this.teacherId = this.classroom.teacher_id;
    this.gradeId = this.classroom.grade_id;
    this.updateEdit();
  }

  updateClassroom() {

    this.gradeErrorMsg = null;
    this.classTeacherErrorMsg = null;
    this.titleErrorMsg = null;
    this.respError = null;

    if(!this.title || this.title.trim().length < 2) {
      this.titleErrorMsg = "Title must be at least 2 characters long.";
      return;
    }

    if(!this.grade) {
      this.gradeErrorMsg = "Select a valid grade.";
      return;
    }
    if(!this.teacherId) {
      this.classTeacherErrorMsg = "Select a teacher.";
      return;
    }

    this.classroomService.updateClasstoom(
      this.classroom.id,
      this.title,
      this.gradeId,
      this.teacherId
    ).subscribe(
      resp => {
        console.log("Response: ", resp);
        this.classroom.title = this.title;
        this.classroom.grade_id = this.gradeId;
        this.classroom.teacher_id = this.teacherId;
        this.ngOnInit();
        this.updateEdit();
      }, err => {
        this.respError = err.error.error;
      }
    );
  }

  delete() {
    this.classroomService.deleteClassroom(this.classroom.id)
      .subscribe(
        resp => {
          this.deleteClassroom.emit(this.classroom.id);
          Swal.fire({
              title: "Deleted!",
              text: "Classroom deleted successfully.",
              type: 'success',
              showConfirmButton: false,
              timer: 1500
            }
          );
        },
        err => {
          Swal.fire("Error!",err.error.error,'error');
        }
      );
  }
}
