import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionExam} from '../../../../Models/Exam/SessionExam';
import {SessionExamService} from '../../services/session-exam.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sessionexam-item',
  templateUrl: './sessionexam-item.component.html',
  styleUrls: ['./sessionexam-item.component.css']
})
export class SessionexamItemComponent implements OnInit {
  @Input() sessionExam: SessionExam;
  @Output() examDeleteHandler: EventEmitter<number> = new EventEmitter<number>();
  deleted:boolean = false;
  constructor(
    private sessionExamService: SessionExamService
  ) { }

  ngOnInit() {
  }

  deleteSessionExam() {
    Swal.showLoading();
    return this.sessionExamService.delete(this.sessionExam.id)
      .subscribe( (resp: any) => {
        this.deleted = true;
        this.examDeleteHandler.emit(this.sessionExam.exam.id);
        Swal.fire({
          title: resp.message,
          text: null,
          type: 'success'
        });
      }, error1 => {
        let errorMsg = "";
        if(error1.error.error.session_exam_id) {
          errorMsg = error1.error.error.session_exam_id;
        } else {
          errorMsg = error1.status + ": " + error1.statusText;
        }
        Swal.fire({
          title: errorMsg,
          text: null,
          type: 'error'
        });
      });
  }

}
