import { Component, OnInit } from '@angular/core';
import {Grade} from '../../../Models/Classrooms/Grade';
import {GradeService} from '../services/grade.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  grades: Grade[];

  newGradeStatus;

  constructor(
    private gradeService: GradeService,
  ) { }

  ngOnInit() {
    this.getGrades();
  }

  getGrades() {
    this.gradeService.getGrades().subscribe(
      grades => this.grades = grades
    );
  }

  toggleNewGradeStatus() {
    this.newGradeStatus = !this.newGradeStatus;
  }

  deleteGrade(gradeID) {
    this.grades.forEach((grade, index) => {
      if (grade.id == gradeID) {
        this.grades.splice(index, 1);
        return;
      }
    });

  }
}
