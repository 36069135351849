import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmployeeListItem} from '../../employees/models/employee-list-item';
import {EmployeeService} from '../../employees/services/employee.service';
import {Subject} from '../../../Models/Subjects/Subject';
import {EmployeeInfo} from '../../employees/models/employee-info';
import {SubjectService} from '../services/subject.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subject-item',
  templateUrl: './subject-item.component.html',
  styleUrls: ['./subject-item.component.css']
})
export class SubjectItemComponent implements OnInit {

  constructor(
    private employeeService: EmployeeService,
    private subjectService: SubjectService
  ) { }

  ngOnInit() {
    this.getEmployee();
  }

  @Input() subject: Subject;
  @Input() employees: EmployeeListItem[];
  @Output() updateSubjects = new EventEmitter();
  employee: EmployeeInfo;
  teacherId: number;
  teacherError: string = null;
  editStatus: boolean = false;

  getEmployee() {
    this.employeeService.getInfo(this.subject.teacher_id).subscribe(
      employee => {
        this.employee = employee;
      }
    );
  }

  toggleEditStatus() {
    this.editStatus = !this.editStatus;
    this.teacherId = this.employee.employee_id;
  }

  updateTeacher() {
    this.teacherError = null;
    console.log(this.subject);
    if(!this.teacherId) {
      this.teacherError = "Select a teacher."
      return;
    }
    this.subjectService.updateTeacher(
      this.subject.id,
      this.teacherId,
      this.subject.class_room_id
      ).subscribe(
        resp => {
          this.updateSubjects.emit();
          Swal.fire(resp.toString() ,"",'success');
        },
      err => {
        Swal.fire("",err.error.error,'error');
      }
    );
  }

  deleteSubject() {
    Swal.showLoading();
    this.subjectService.deleteSubject(
      this.subject.id,
      this.subject.class_room_id
    ).subscribe(
      resp => {
        this.updateSubjects.emit();
        Swal.fire(resp.toString() ,"",'success');
      },
      err => {
        Swal.fire("",err.error.error,'error');
      }
    );
  }
}
