import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubjectsRoutingModule } from './subjects-routing.module';
import { SubjectsHomeComponent } from './subjects-home/subjects-home.component';
import {FormsModule} from '@angular/forms';
import { SubjectItemComponent } from './subject-item/subject-item.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { NewSubjectComponent } from './new-subject/new-subject.component';

@NgModule({
  declarations: [SubjectsHomeComponent, SubjectItemComponent, NewSubjectComponent],
  imports: [
    CommonModule,
    FormsModule,
    SubjectsRoutingModule,
    SweetAlert2Module
  ]
})
export class SubjectsModule { }
