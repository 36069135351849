import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '../../Models/Account';
import {AccountService} from '../../Services/account.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {

  @Input() account: Account;
  @Output() deleted = new EventEmitter();
  editStatus = false;
  accountTitle: string;
  accountType: string;
  resp: any = null;
  accountTitleError: any = null;
  accountTypeError: any = null;
  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.accountTitle = this.account.account_title;
    this.accountType = this.account.type;
  }

  enableEdit() {
    this.editStatus = true;
  }
  disableEdit() {
    this.editStatus = false;
  }
  toggleEdit() {
    this.editStatus = !this.editStatus;
  }

  updateAccount() {
    Swal.showLoading();
    this.accountTitleError = null;
    this.accountTypeError = null;
    this.accountService.updateAccount(this.account.id, this.accountTitle, this.accountType)
      .subscribe((account) => {
        this.account = account;
        this.disableEdit();
        Swal.close();
      }, error => {
        error = error.error;
        if (error.account_title) {
          this.accountTitleError = error.account_title;
        }
        Swal.close();
      });
  }

  deleteAccount() {
    Swal.showLoading();
    this.accountService.deleteAccount(this.account.id)
      .subscribe(resp => {
        this.deleted.emit(this.account.id);
        Swal.fire(resp.message, '', 'success');
      }, error => {
        if (error.error.error){
          Swal.fire(error.statusText, error.error.error_message, 'error');
        } else {
          Swal.fire(error.statusText, '', 'error');
        }
      });
  }
}
