import { Component, OnInit, OnChanges } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StudentService} from '../services/student.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.css']
})
export class StudentDetailsComponent implements OnInit {
  registrationNumber: string;
  constructor(
    private route: ActivatedRoute,
    private studentService: StudentService
  ) { }

  personal: any;
  enrollment: any;
  guardian: any;
  contact: any;
  studentFound: boolean;

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.registrationNumber = routeParams.registrationNumber;
      this.getDetails();
    });
  }

  getDetails() {
    Swal.showLoading();
    this.studentService.studentDetails(this.registrationNumber)
      .subscribe(response => {
        this.studentFound = true;
        this.personal = response.data.personal;
        this.guardian = response.data.guardian;
        this.enrollment = response.data.enrollment;
        this.contact = response.data.contact;
        Swal.close();
      }, error1 => {
        this.studentFound = false;
        let errMsg:string = "";
        if(error1.error.success == false) {
          errMsg = error1.error.message;
        } else {
          errMsg = error1.status + ":" +  error1.statusText;
        }
        Swal.fire(errMsg,null, 'error');
      });
  }

}
