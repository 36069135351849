import {Component, Input, OnInit} from '@angular/core';
import {EmployeeService} from '../../services/employee.service';
import {SubjectItem} from '../../models/subject-item';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit {
  @Input() employeeID: number;
  subjects: SubjectItem[];
  constructor(private _employee: EmployeeService) { }

  ngOnInit() {
    this.updateSubjects();
  }

  updateSubjects() {
    this._employee.subjects(this.employeeID).subscribe(
      subjects => this.subjects = subjects
    );
  }
}
