import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AfterLoginComponent} from '../../after-login/after-login.component';
import {AuthGuard} from '../../Guards/auth.guard';
import {HomeComponent} from './exam/home/home.component';
import {SessionexamHomeComponent} from './session-exam/sessionexam-home/sessionexam-home.component';
import {ResultsHomeComponent} from './results/results-home/results-home.component';
import {SubjectResultComponent} from './results/subject-result/subject-result.component';
import {AddSubjectResultComponent} from './results/add-subject-result/add-subject-result.component';


const routes: Routes = [{
  path: 'admin/exam',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent }
  ]
}, {
  path: 'admin/sessionexam',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: SessionexamHomeComponent }
  ]
}, {
  path: 'admin/result',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: ResultsHomeComponent },
    { path: ':session_exam_id/:class_subject_id', component: SubjectResultComponent },
    { path: ':session_exam_id/:class_subject_id/add', component: AddSubjectResultComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ExamresultsRoutingModule { }
