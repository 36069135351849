import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AfterLoginComponent} from "../../after-login/after-login.component";
import {AuthGuard} from "../../Guards/auth.guard";
import {HomeComponent} from "./home/home.component";
import {ClassroomAttendanceInputComponent} from "./classroom-attendance/classroom-attendance-input/classroom-attendance-input.component";
import {ClassroomAttendanceViewSingleComponent} from "./classroom-attendance/classroom-attendance-view-single/classroom-attendance-view-single.component";
import {ClassroomAttendanceViewRangeComponent} from "./classroom-attendance/classroom-attendance-view-range/classroom-attendance-view-range.component";

const routes: Routes = [{
  path: 'admin/attendance/students',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent},
    { path: 'classroom/:classroomID', component: ClassroomAttendanceInputComponent },
    { path: 'classroom/:classroomID/:date/view', component: ClassroomAttendanceViewSingleComponent },
    { path: 'student/:registrationNumber/:startDate/:endDate/range', component: ClassroomAttendanceViewRangeComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentAttendanceRoutingModule { }
