import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseSubjectRoutingModule } from './base-subject-routing.module';
import {FormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { HomeComponent } from './home/home.component';
import { NewSubjectComponent } from './new-subject/new-subject.component';
import { SubjectComponent } from './subject/subject.component';

@NgModule({
  declarations: [HomeComponent, NewSubjectComponent, SubjectComponent],
  imports: [
    CommonModule,
    BaseSubjectRoutingModule,
    FormsModule,
    SweetAlert2Module
  ]
})
export class BaseSubjectModule { }
