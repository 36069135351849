import {Component, Input, OnInit} from '@angular/core';
import {EmployeeListItem} from '../../models/employee-list-item';
import {EmployeeService} from '../../services/employee.service';
import {Image} from '../../../../Models/User/Image';

@Component({
  selector: 'app-employee-item',
  templateUrl: './employee-item.component.html',
  styleUrls: ['./employee-item.component.css']
})
export class EmployeeItemComponent implements OnInit {
  @Input() employee: EmployeeListItem;
  EmployeeImage: Image;
  constructor(private _employee: EmployeeService) { }

  ngOnInit() {
    this.getImage(50);
  }
  getImage(resolution) {
    if(!resolution) { resolution = 50; }
    return this._employee.dpImage(this.employee.employee_id, resolution).subscribe(
      dp => {
        this.EmployeeImage = dp;
      }
    );
  }
}
