import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Grade} from '../../../../../Models/Classrooms/Grade';
import {Classroom} from '../../../../../Models/Classrooms/Classroom';
import {GradeService} from '../../../../grade/services/grade.service';
import {ClassroomService} from '../../../../classrooms/services/classroom.service';
import {AcademicSession} from '../../../../sessions/models/AcademicSession';
import {SessionService} from '../../../../sessions/services/session.service';
import {AccountService} from '../../../Services/account.service';
import {Account} from '../../../Models/Account';

@Component({
  selector: 'app-classroom-select',
  templateUrl: './classroom-select.component.html',
  styleUrls: ['./classroom-select.component.css']
})
export class ClassroomSelectComponent implements OnInit {
  sessions: AcademicSession[];
  grades: Grade[];
  classrooms: Classroom[];
  accounts: Account[];
  mGrade: number;
  mClassroom: number;
  mSession: number;
  mAccount: number;
  mAmount: number;
  errorMsg: string = null;
  errorClassroom = null;
  errorAccount = null;
  errorAmount = null;

  constructor(
    private gradeService: GradeService,
    private classroomService: ClassroomService,
    private sessionService: SessionService,
    private accountsService: AccountService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getGrades();
    this.currentSessionClassrooms();
    this.getSessions();
    this.getAccounts();
  }
  resetClassroomModel() {
    this.mClassroom = null;
  }

  getSessions() {
    this.sessionService.getSessions().subscribe(
      sessions => {
        this.sessions = sessions;
        for (const session of sessions) {
          if (session.current) {
            this.mSession = session.id;
          }
        }
      }
    );
  }

  getGrades() {
    this.grades = null;
    this.gradeService.getGrades().subscribe(
      grades => this.grades = grades
    );
  }
  currentSessionClassrooms() {
    return this.classroomService.getCurrent().subscribe(
      classrooms => this.classrooms = classrooms
    );
  }
  getAccounts() {
    this.accountsService.getAccounts()
      .subscribe(accounts => {
        this.accounts = accounts;
      });
  }

  searchClassroom() {
    this.errorMsg = null;
    if(!this.mClassroom) {
      this.errorMsg = 'Select a classroom to continue.';
      return;
    }
    // this.router.navigate(['admin/students/list', this.mClassroom]);
    alert('redirecting...');
  }

  chargeClassroom() {
    this.errorClassroom = null;
    this.errorAccount = null;
    this.errorAmount = null;
    if (!this.mClassroom) {
      this.errorClassroom = 'Please select a classroom.';
      return;
    }
    if (!this.mAccount) {
      this.errorAccount = 'Please select an account.';
      return;
    }
    if ((!this.mAmount) || (!(this.mAmount > 0))) {
      this.errorAmount = 'Please enter a valid amount.';
      return;
    }


    this.router.navigate(['admin/finance/students/classroom',
      this.mClassroom, this.mAccount, this.mAmount]);
  }

}
