import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '../../../Models/Account';
import {AccountService} from '../../../Services/account.service';
import Swal from 'sweetalert2';
import {TransactionService} from '../../../Services/transaction.service';

@Component({
  selector: 'app-add-student-transaction',
  templateUrl: './add-student-transaction.component.html',
  styleUrls: ['./add-student-transaction.component.css']
})
export class AddStudentTransactionComponent implements OnInit {
  @Output() cancelNewTx = new EventEmitter();
  @Output() addedNewTx = new EventEmitter();
  @Input() studentRegNumber: number;
  @Input() accounts: Account[];
  mAccount: number;
  accountErr: string;
  mAmount: number;
  amountError: string;
  mComment: string;
  constructor(
    private accountService: AccountService,
    private transactionService: TransactionService
  ) { }

  ngOnInit() {
    this.accountErr = null;
    this.amountError = null;
  }

  cancelTx($event) {
    this.cancelNewTx.emit();
  }

  addTx() {
    this.accountErr = null;
    this.amountError = null;
    if (!this.mAccount) {
      this.accountErr = 'Please select an account.';
      return;
    }
    if ((!this.mAmount) || (!(this.mAmount > 0))) {
      this.amountError = 'Please enter an amount to charger.';
      return;
    }
    Swal.showLoading();
    this.transactionService.addStudentTransaction(
      this.studentRegNumber, this.mAccount, this.mAmount, this.mComment
    ).subscribe(resp => {
      this.addedNewTx.emit(resp);
      this.mComment = null;
      this.mAmount = null;
      this.cancelNewTx.emit();
      Swal.close();
    }, error => {
      Swal.fire('Error', error.error.message, 'error');
    });
  }
}
