import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExamresultsRoutingModule } from './examresults-routing.module';
import { HomeComponent } from './exam/home/home.component';
import { ExamComponent } from './exam/exam/exam.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {FormsModule} from '@angular/forms';
import { CreateExamComponent } from './exam/create-exam/create-exam.component';
import { SessionexamHomeComponent } from './session-exam/sessionexam-home/sessionexam-home.component';
import { SessionexamItemComponent } from './session-exam/sessionexam-item/sessionexam-item.component';
import { AddSessionExamComponent } from './session-exam/add-session-exam/add-session-exam.component';
import {ResultsHomeComponent} from './results/results-home/results-home.component';
import { SubjectResultComponent } from './results/subject-result/subject-result.component';
import { AddSubjectResultComponent } from './results/add-subject-result/add-subject-result.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HomeComponent,
    ExamComponent,
    CreateExamComponent,
    SessionexamHomeComponent,
    SessionexamItemComponent,
    AddSessionExamComponent,
    ResultsHomeComponent,
    SubjectResultComponent,
    AddSubjectResultComponent
  ],
  imports: [
    CommonModule,
    ExamresultsRoutingModule,
    SweetAlert2Module.forRoot(),
    FormsModule,
    NgbDatepickerModule
  ]
})
export class ExamresultsModule { }
