import { Component, OnInit } from '@angular/core';
import {GradeService} from '../../../grade/services/grade.service';
import {Grade} from '../../../../Models/Classrooms/Grade';
import {ClassroomService} from '../../../classrooms/services/classroom.service';
import {Classroom} from '../../../../Models/Classrooms/Classroom';
import Swal from 'sweetalert2';
import {Subject} from '../../../../Models/Subjects/Subject';
import {SessionExam} from '../../../../Models/Exam/SessionExam';
import {SubjectService} from '../../../subjects/services/subject.service';
import {SessionExamService} from '../../services/session-exam.service';
import {ResultService} from '../../services/result.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-results-home',
  templateUrl: './results-home.component.html',
  styleUrls: ['./results-home.component.css']
})
export class ResultsHomeComponent implements OnInit {
  grades: Grade[];
  mGradeID: number = null;

  classrooms: Classroom[];
  mClassroomID: number = null;

  classSubjects: Subject[];
  mClassSubjectID: number = null;

  exams: SessionExam[];
  mExamID: number = null;

  constructor(
    private gradeService: GradeService,
    private classroomService: ClassroomService,
    private subjectService: SubjectService,
    private sessionExamService: SessionExamService,
    private resultService: ResultService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getGrades();
    this.getClassrooms();
    this.getExams();
  }

  getGrades() {
    Swal.showLoading();
    this.gradeService.getGrades().subscribe(
      grades => {
        this.grades = grades;
        Swal.close();
      }, error1 => {
        Swal.fire('Error loading grades!', '', 'error');
      }
    );
  }

  getClassrooms() {
    Swal.showLoading();
    this.classroomService.getCurrent().subscribe(
      classrooms => {
        this.classrooms = classrooms;
        Swal.close();
      },  error1 => {
        Swal.fire('Error loading classrooms!', '', 'error');
      }
    );
  }

  getClassSubjects() {
    Swal.showLoading();
    this.subjectService.getSubjects(this.mClassroomID).subscribe(
      subjects => {
        this.classSubjects = subjects;
        // console.log(subjects);
        Swal.close();
      }, error1 => {
        Swal.fire('Error loading subjects.', '', 'error');
      }
    );
  }

  getExams() {
    Swal.showLoading();
    this.sessionExamService.all().subscribe(
      exams => {
        this.exams = exams.data.session_exams;
        Swal.close();
      }, error1 => {
        Swal.fire('Error while loading exams!', '', 'error');
      }
    );
  }

  checkResultStatus() {
    Swal.showLoading();
    this.resultService.validateResult(this.mExamID, this.mClassSubjectID)
      .subscribe(resp => {
        if (resp.result_announced == true) {
          this.router.navigate(['/admin/result',
            this.mExamID,
            this.mClassSubjectID
          ]);
        } else {
          this.router.navigate(['/admin/result',
            this.mExamID,
            this.mClassSubjectID,
            'add'
          ]);
        }
      }, error1 => {
        console.log('Error 1: ', error1);
      });
  }

  resetClassroom() {
    this.mClassroomID = null;
    this.resetClassSubject();
  }
  resetClassSubject() {
    this.mClassSubjectID = null;
    this.getClassSubjects();
    this.resetExam();
  }
  resetExam() { this.mExamID = null; }
}
