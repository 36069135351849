import { Component, OnInit } from '@angular/core';
import { SubjectService } from "../../subjects/services/subject.service";
import { Subject } from "../../../Models/Subjects/Subject";
import { ActivatedRoute } from "@angular/router";
import { ClassroomService } from "../services/classroom.service";
import { ClassroomDetails } from "../classroom-list/ClassroomDetails";
import { DailyLessonNotificationService } from '../services/daily-lesson-notification.service';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-daily-lessons-notification',
  templateUrl: './daily-lessons-notification.component.html',
  styleUrls: ['./daily-lessons-notification.component.css']
})
export class DailyLessonsNotificationComponent implements OnInit {
  subjects: Subject[];
  classroomId;
  classDetails: ClassroomDetails;
  subjectsData: any[];

  mLessonDate: NgbDate;
  lessonDateError: string;

  constructor(
    private subjectService: SubjectService,
    private route: ActivatedRoute,
    private classroomService: ClassroomService,
    private lessonNotificationService: DailyLessonNotificationService,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) { }

  ngOnInit() {
    this.subjectsData = [];
    this.route.paramMap.subscribe(params => {
      this.classroomId = parseInt(params.get('id'));
      if(!this.classroomId){ // ID not valid
        // TODO
      }
      this.fetchDetails(this.classroomId);
      this.getSubjects();
    });

    let d = new Date();
    this.mLessonDate = new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate());
  }

  getSubjects() {
    this.subjectService.getSubjects(this.classroomId)
      .subscribe(resp => {
        let subjects: Subject[] = resp;
        for(let i=0; i< subjects.length; i++) {
          let subj = {
            subject : subjects[i],
            lesson_title : "",
            page_number : 0,
            homework_status : true
          };
          this.subjectsData.push(subj);
        }

      }, error => {
        console.log("Error: ", error);
      });
  }

  fetchDetails(classroomID) {
    this.classroomService.getDetails(classroomID).subscribe(
      resp => {
        this.classDetails = resp;
        // console.log('Details: ', this.classDetails);
      }
    );
  }

  updatePlan() {
    let data:any[] = [];
    let error = false;
    for(let i = 0; i < this.subjectsData.length; i++) {
      this.subjectsData[i].page_error = null;
      this.subjectsData[i].title_error = null;
      if(this.subjectsData[i].lesson_title == '-') {
        continue;
      }
      if(!(this.subjectsData[i].page_number > 0)) {
        error = true;
        this.subjectsData[i].page_error = "Enter a valid page number."
      }
      if (!this.subjectsData[i].lesson_title || this.subjectsData[1].lesson_title.trim() == "") {
        error = true;
        this.subjectsData[i].title_error = "Enter a valid lesson title.";
      }
      let dataObj = {
        class_subject_id: this.subjectsData[i].subject.id,
        lesson_title: this.subjectsData[i].lesson_title,
        page_number: this.subjectsData[i].page_number,
        homework_status: this.subjectsData[i].homework_status
      };

      data.push(dataObj);
    }

    if(!this.mLessonDate) {
      error = true;
      this.lessonDateError = "Please select a valid date.";
    }

    if(error) {
      return;
    }

    Swal.showLoading();
    this.lessonNotificationService.updateDailyLessons(
      this.classroomId,
      this.ngbDateParserFormatter.format(this.mLessonDate),
      data
    ).subscribe(resp => {
      Swal.fire("Data Updated.","","success");
      }, error1 => {
        console.error("Error: ", error1);
        if(error1.status == 409) {
          // Data already updated.
        }
      });
  }
}
