import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Account} from '../Models/Account';
import {HttpClient} from '@angular/common/http';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/account'
    ).pipe(map(
      (accounts: any) => accounts.data as Account[]
    ));
  }

  getAccount(accountID): Observable<Account> {
    return this.http.get<Account>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/account/' + accountID
    ).pipe(map(
      (account: any) => account.data as Account
    ));
  }

  addAccount(title: string, type: string): Observable<Account> {
    return this.http.post<Account>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/account', {
        account_title: title,
        account_type: type
      }
    ).pipe(map(
      (account) => account as Account
    ));
  }

  updateAccount(id: number, title: string, type: string): Observable<Account> {
    return this.http.put<Account>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/account/' + id, {
        account_title: title,
        account_type: type
      }
    ).pipe(map(
      (account) => account as Account
    ));
  }

  deleteAccount(id: number): Observable<any> {
    return this.http.delete<any>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/account/' + id);
  }

  getClassroomList(classroomID: number): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/account/students/classroom/' + classroomID
    );
  }

  chargeClassroom(classroomID: number,
                  accountID: number, students: any,
                  comment: string
                  ): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/transaction/classroom', {
        classroom_id: classroomID,
        account_id: accountID,
        students,
        comment
      });
  }

  classroomSummary(classroomID: number): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/classroom/summary/' + classroomID
    );
  }

  getStudentTransactions(regNumber: number): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/students/finance/transaction/student/' + regNumber
    );
  }

}
