import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {State} from '../../Models/User/Profile/Address/State';
import {Observable} from 'rxjs';
import {GlobalValues} from '../../../globals';
import {map} from 'rxjs/operators';
import {District} from '../../Models/User/Profile/Address/District';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient
  ) { }

  getStates():Observable<State[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/address/states'
    ).pipe(
      map(states => <State[]> states)
    );
  }

  getDistricts():Observable<District[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/address/districts'
    ).pipe(
      map(states => <District[]> states)
    );
  }
}
