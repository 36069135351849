import { Component, OnInit } from '@angular/core';
import {Grade} from "../../../Models/Classrooms/Grade";
import {Classroom} from "../../../Models/Classrooms/Classroom";
import {GradeService} from "../../grade/services/grade.service";
import {ClassroomService} from "../../classrooms/services/classroom.service";
import {Router} from "@angular/router";
import {NgbCalendar, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  grades: Grade[];
  gradeError = null;
  classrooms: Classroom[];
  classroomError = null;
  mGrade = null;
  mClassroom = null;
  attendanceDate = null;
  constructor(
    private gradeService: GradeService,
    private classroomService: ClassroomService,
    private dateParserFormatter: NgbDateParserFormatter,
    private ngbCalendar: NgbCalendar,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getGrades();
    this.getClassrooms();
    this.attendanceDate = this.ngbCalendar.getToday();
  }

  getGrades() {
    this.gradeService.getGrades().subscribe(resp => {
      this.grades = resp;
      console.log("Grades: ", this.grades);
    });
  }

  getClassrooms() {
    this.classroomService.getCurrent().subscribe(resp => {
      this.classrooms = resp;
      console.log("Classrooms: ", this.classrooms);
    });
  }

  processClassroomInput() {
    this.gradeError = null;
    this.classroomError = null;

    if(!this.mGrade) {
      this.gradeError = "Select a grade.";
      return;
    }

    if(!this.mClassroom) {
      this.classroomError = "Select a classroom.";
      return;
    }

    this.router.navigate([this.router.url, 'classroom', this.mClassroom])
  }

  processClassroomShow() {
    let attendanceDate = this.dateParserFormatter.format(this.attendanceDate);
    let classroomID = this.mClassroom;
    this.router.navigate([this.router.url,'classroom',classroomID,attendanceDate,'view']);
  }

}
