import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseSubject} from '../../../Models/Subjects/BaseSubject';
import {Category} from '../../../Models/Subjects/Category';
import {SubjectService} from '../../subjects/services/subject.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit {

  constructor(
    private subjectService: SubjectService
  ) { }

  @Input() baseSubject: BaseSubject;
  @Input() category: Category;
  @Input() categories: Category[];
  @Output() refreshList = new EventEmitter();

  editStatus: boolean = false;
  mTitle: string;
  mCategory: number;
  titleError: string = null;
  categoryError: string = null;
  respError: string = null;

  ngOnInit() {
    this.mTitle = this.baseSubject.title;
    this.mCategory = this.baseSubject.category_id;
  }

  toggleEditStatus() {
    this.editStatus = !this.editStatus;
  }

  updateSubject() {
    this.titleError = null;
    this.categoryError =null;
    this.respError = null;

    if(this.mTitle.length < 2) {
      this.titleError = "Subject title must be at least two characters long.";
      return;
    }
    if(!this.mCategory) {
      this.categoryError = "Select a category."
      return;
    }
    Swal.showLoading();
    this.subjectService.updateBaseSubject(
      this.baseSubject.id, this.mTitle, this.mCategory
    ).subscribe(
      resp => {
        Swal.fire(resp.toString(),"","success");
        this.refreshList.emit();
      },
      err => {
        Swal.fire(err.error.error,"","error");
        this.respError = err.error.error;
      }
    )
  }

  deleteBaseSubject() {
    Swal.showLoading();
    this.subjectService.deleteBaseSubject(this.baseSubject.id)
      .subscribe(
        resp => {
          Swal.fire(resp.toString(),null,'success');
          this.refreshList.emit();
        }, err => {
          Swal.fire(err.error.error,null,'error');
        }
      );
  }
}
