import {Component, Input, OnInit} from '@angular/core';
import {AcademicSession} from '../../models/AcademicSession';

@Component({
  selector: 'app-current-session',
  templateUrl: './current-session.component.html',
  styleUrls: ['./current-session.component.css']
})
export class CurrentSessionComponent implements OnInit {

  @Input() CurrentSession: AcademicSession;
  constructor() { }

  ngOnInit() {
  }

}
