import { Component, OnInit } from '@angular/core';
import { Account } from '../Models/Account';
import {AccountService} from '../Services/account.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  newAccountStatus = false;
  accounts: Account[];

  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.getAccounts();
  }

  getAccounts() {
     this.accountService.getAccounts().subscribe(
       accounts => this.accounts = accounts
     );
  }

  cancelNewAccount() {
    this.newAccountStatus = false;
  }

  addNewAccount() {
    this.newAccountStatus = true;
  }

  accountAdded($event) {
    this.accounts.push($event);
    this.cancelNewAccount();
  }

  deleteAccount($event) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.accounts.length; i++) {
      if (this.accounts[i].id == $event) {
        this.accounts.splice(i, 1);
      }
    }
  }
}
