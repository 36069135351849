import { Component, OnInit } from '@angular/core';
import {GradeService} from '../../grade/services/grade.service';
import {Grade} from '../../../Models/Classrooms/Grade';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import {ClassroomService} from '../../classrooms/services/classroom.service';
import {SubjectService} from '../services/subject.service';
import {Subject} from '../../../Models/Subjects/Subject';
import {EmployeeListItem} from '../../employees/models/employee-list-item';
import {EmployeeService} from '../../employees/services/employee.service';
import {BaseSubject} from '../../../Models/Subjects/BaseSubject';
import {Category} from '../../../Models/Subjects/Category';

@Component({
  selector: 'app-subjects-home',
  templateUrl: './subjects-home.component.html',
  styleUrls: ['./subjects-home.component.css']
})

export class SubjectsHomeComponent implements OnInit {

  grades: Grade[];
  classrooms: Classroom[];
  subjects: Subject[];
  baseSubjects: BaseSubject[];
  categories: Category[];

  mGrade: number;
  mClassroom: number;
  employees: EmployeeListItem[];
  constructor(
    private gradeService: GradeService,
    private classroomService: ClassroomService,
    private subjectService: SubjectService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.getGrades();
    this.currentSessionClassrooms();
    this.getCategories();
    this.getBaseSubjects();
    this.getEmployees();
  }

  resetClassroomModel() {
    this.mClassroom = null;
  }

  getGrades() {
    this.grades = null;
    this.gradeService.getGrades().subscribe(
      grades => this.grades = grades
    );
  }

  getEmployees() {
    this.employeeService.getList().subscribe(
      employees => this.employees = employees
    );
  }

  currentSessionClassrooms() {
    return this.classroomService.getCurrent().subscribe(
      classrooms => this.classrooms = classrooms
    );
  }

  // Get subjects for specific classroom
  getSubjects(classroom_id) {
    this.subjectService.getSubjects(classroom_id).subscribe(
      subjects => {
        this.subjects = subjects;
        console.log(subjects);
      }
    );
  }

  updateSubjects() {
    if(!this.mClassroom) { return; }
    this.getSubjects(this.mClassroom);
  }

  //HELPERS
  classroomTitle(id: number) {
    let classroom: Classroom;
    this.classrooms.forEach(function (cr) {
      if(cr.id == id) { classroom = cr; return; }
    });

    return classroom.title ? classroom.title : "";
  }

  ////////////
  // BASE SUBJECTS
  ///////////////

  getBaseSubjects() {
    this.subjectService.baseSubjects().subscribe(
      resp => this.baseSubjects = <BaseSubject[]> resp
    );
  }

  //////////////////
  //  Categories
  //////////////////
  getCategories() {
    this.subjectService.categories().subscribe(
      categories => this.categories = <Category[]> categories
    );
  }

}
