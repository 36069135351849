import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Subject} from '../../../Models/Subjects/Subject';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';
import {BaseSubject} from '../../../Models/Subjects/BaseSubject';
import {Category} from '../../../Models/Subjects/Category';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor(
    private http: HttpClient
  ) { }

  getSubjects(classroom_id: number): Observable<Subject[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/classroom/' + classroom_id
    ).pipe(
      map(subjects => <Subject[]> subjects)
    );
  }

  getSubject(subject_id: number): Observable<Subject> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/' + subject_id
    ).pipe(
      map(subject => <Subject> subject)
    );
  }

  baseSubjects(): Observable<BaseSubject[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/basesubjects'
    ).pipe(
      map(basesubjects => <BaseSubject[]> basesubjects )
    );
  }

  categories(): Observable<Category[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/categories'
    ).pipe(
      map(categories => <Category[]> categories )
    );
  }

  addSubject(classroomID, baseSubjectID, teacherID):Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects',
      {
        'classroom_id' : classroomID,
        'basesubject_id' : baseSubjectID,
        'teacher_id' : teacherID
      }
    ).pipe(
      resp => { return resp; }
    );
  }

  updateTeacher(subjectID, teacherID, classroomID) {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/' + subjectID,
      {
        teacher_id: teacherID,
        classroom_id: classroomID
      }
    ).pipe(
      resp => { return resp; });
  }

  deleteSubject(subjectID, classroomID) {
    return this.http.delete(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/' + subjectID
    ).pipe(
      resp => { return resp; }
    );
  }

  // BaseSubject Methods
  addBaseSubject(title:string, categoryID:number) {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/basesubject', {
        'title' : title,
        'category_id': categoryID
      }
    ).pipe(
      resp => { return resp; }
    );
  }

  updateBaseSubject(baseSubjectID, title, categoryID) {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/basesubject/' + baseSubjectID, {
        'title' : title,
        'category_id': categoryID
      }
    ).pipe(
      resp => { return resp; }
    );
  }


  deleteBaseSubject(baseSubjectID) {
    return this.http.delete(
      GlobalValues.BASE_ENDPOINT_URL +
      '/subjects/basesubject/' + baseSubjectID
    ).pipe(
      resp => { return resp; }
    );
  }

}
