import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Dashboard} from '../models/dashboard.model';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  get(): Observable<Dashboard> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/dashboard'
    ).pipe(map(resp => resp as Dashboard));
  }
}
