import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {GlobalValues} from '../../../globals';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router) { }

  loginAdmin(username, password) {
    return this.http.post(GlobalValues.BASE_ENDPOINT_URL + '/signin',
      {
        username: username,
        password: password
      }
      );
  }

  isLoggedIn() {
    return !! localStorage.getItem('access_token');
  }

  tokenExpired() {
    const expiryTime = new Date(localStorage.getItem('expire_time'));
    const timeNow = new Date();
    return (expiryTime.getTime() <= timeNow.getTime());
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expire_time');
    this.router.navigate(['/login']);
  }
}
