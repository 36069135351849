import { Component, OnInit } from '@angular/core';
import {SchoolService} from '../services/school.service';
import {SchoolInfo} from '../models/SchoolInfo';
import {SchoolEmail} from '../models/SchoolEmail';
import {SchoolPhone} from '../models/SchoolPhone';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  // private name : string;
  info : SchoolInfo;
  emails : SchoolEmail[];
  phones : SchoolPhone[];

  constructor(private _schoolService: SchoolService) {
  }

  ngOnInit() {
    this.updateInfo();
    this.updateEmails();
    this.updatePhones();
  }

  updateInfo() {
    this._schoolService.getInfo().subscribe(
      info => this.info = info,
      console.log
    );
  } //updateInfo Ends

  updateEmails() {
    this._schoolService.getEmails().subscribe(
      emails => this.emails = emails,
      console.log
    );
  } // updateEmails Ends

  updatePhones() {
    this._schoolService.getPhones().subscribe(
      phones => this.phones = phones,
      console.log
    );
  } // updateEmails Ends


  // School Name Methods
  getName():string {
    return this.info == undefined ? null : this.info.full_name;
  }

  setName(_name) {
    // this.name = _name;
  }

  // School Abbreviation
  getAbbreviation():string {
    return this.info == undefined ? null : this.info.short_name;
  }
  setAbbreviation(_abbreviation) {
    // this.abbreviation = _abbreviation;
  }

  // School Phone
  getPhone(): SchoolPhone[] {
    return this.phones == undefined ? null : this.phones;
  }
  public setPhone(_phone) {
    // this.phone = _phone;
  }

  // School Email
  public getEmails():SchoolEmail[] {
    return this.emails == undefined ? null : this.emails;
  }

  public setEmail(_email) {
    // this.email = _email;
  }
}
