import { Component, OnInit } from '@angular/core';
import {AuthService} from "./services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  public username = "";
  public password = "";
  public errorMessage = null;

  constructor (
    private authService: AuthService,
    private router: Router)
  { }

  ngOnInit() {
  }

  loginAdmin(event) {
    let adminUsername = 'adm|' + this.username;
    this.errorMessage = null;
    event.preventDefault();
    this.authService.loginAdmin(adminUsername, this.password)
      .subscribe(
        (response: any) => {
          console.log(response);
          // response = response.json();
          let access_token = response.access_token;
          let expire_in_seconds = parseInt(response.expires_in);
          let expire_time = new Date();
          expire_time.setSeconds(expire_time.getSeconds() + expire_in_seconds);

          let expire_time_str = expire_time.toString();
          localStorage.setItem('access_token',access_token);
          localStorage.setItem('expire_time',expire_time_str);
          return this.router.navigate(['/admin/home']);

        },
        error => {
          this.errorMessage = error.error.error;
        }
      );
  } //loginGuardian

} //Component Ends
