import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {Account} from '../../../Models/Account';
import {AccountService} from '../../../Services/account.service';
import {ClassroomService} from '../../../../classrooms/services/classroom.service';

@Component({
  selector: 'app-classroom-students-list',
  templateUrl: './classroom-students-list.component.html',
  styleUrls: ['./classroom-students-list.component.css']
})

export class ClassroomStudentsListComponent implements OnInit {

  classroom: any;
  account: Account;
  students: any;
  amount: number;
  comment: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private classroomService: ClassroomService
  ) { }

  ngOnInit() {
    Swal.showLoading();
    this.activatedRoute.params.subscribe(routeParams => {
      this.amount = routeParams.amount;
      this.getAccount(routeParams.accountID);
      this.getClassroomDetails(routeParams.classroomID);
    });
  }

  getClassroomDetails(classroomID) {
    this.classroomService.getDetails(classroomID)
      .subscribe(resp => {
        console.log('Resp: ', resp);
        this.classroom = resp;
        this.students = resp.students;
        for (let i = 0; i < this.students.length; i++) {
          this.students[i].charge_amount = this.amount;
          this.students[i].amount_error = false;
        }
        Swal.close();
      }, error => {
        Swal.fire('Classroom not found.', '', 'error');
        this.router.navigate(['admin/finance/students']);
      });
  }

  getAccount(accountID) {
    this.accountService.getAccount(accountID).subscribe(
      account => {
        this.account = account;
      }, error => {
        Swal.fire('Account not found.', '', 'error');
        this.router.navigate(['admin/finance/students']);
      }
    );
  }

  chargeStudents() {
    let error = false;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].charge_amount < 0) {
        this.students[i].amount_error = true;
        error = true;
      } else {
        this.students[i].amount_error = false;
      }
    }

    if (error) {
      return;
    }
    Swal.showLoading();
    this.accountService.chargeClassroom(
      this.classroom.id, this.account.id,
      this.students, this.comment).subscribe(
      resp => {
        if (resp.success) {
          Swal.fire(resp.message, '', 'success');
          this.router.navigate(['/admin/finance/students']);
        }
      }, error => {
        console.log('Error: ', error);
        Swal.fire(error.error.message, '', 'error');
      }
    );
  }

}
