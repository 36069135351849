import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {AcademicSession} from '../models/AcademicSession';
import {HttpClient} from '@angular/common/http';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient) { }

  getSessions(): Observable<AcademicSession[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/session/all'
    ).pipe(
      map(sessions => <AcademicSession[]> sessions)
    );
  }

  createSession(title, startDate, endDate): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/session', {
        title,
        start_date : startDate,
        end_date : endDate
      }
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
