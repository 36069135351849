import { Component, OnInit } from '@angular/core';
import {State} from "../../../Models/User/Profile/Address/State";
import {District} from "../../../Models/User/Profile/Address/District";
import {AddressService} from "../../../Services/Address/address.service";
import {NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {EmployeeService} from "../services/employee.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {
  states: State[];
  districts: District[];

  mFirstName: string;
  firstNameError: string;
  mMiddleName: string;
  mLastName: string;

  mGender: string;
  genderError: string;

  mDOB: NgbDate;
  DOBError: string;

  profilePhoto: string;

  mCellNumber: string;
  cellNumberError: string;

  mEmail: string;
  emailError: string;

  mCNIC: string;
  CNICError: string;

  mPosition: string;
  positionError: string;

  mDesignation: string;
  designationError: string;

  mQualification: string;
  qualificationError: string;

  mJoiningDate: NgbDate;
  joiningDateError: string;

  mUsername: string;
  usernameError: string;

  mPassword: string;
  passwordError: string;

  mAddressState: string;
  addressStateError: string;
  mAddressDistrict: string;
  addressDistrictError: string;
  mAddressCity: string;
  addressCityError: string;
  mAddressL1: string;
  addressL1Error: string;
  mAddressL2: string;
  addressL2Error: string;

  mainError: string;
  constructor(
    private addressService: AddressService,
    private dateParserFormatter: NgbDateParserFormatter,
    private employeeService: EmployeeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getStates();
    this.getDistricts();
  }

  updateProfilePic(imageBase64) {
    this.profilePhoto = imageBase64;
  }
  onStateChange() {
    this.mAddressDistrict = null;
  }

  getStates() {
    this.addressService.getStates().subscribe(
      states => this.states = states,
      error1 => this.states = []
    )
  }
  getDistricts() {
    this.addressService.getDistricts().subscribe(
      districts => this.districts = districts,
      error1 => this.districts = []
    )
  }

  addEmployee() {
    let error = false;

    this.mainError = null;
    this.firstNameError = null;
    this.genderError = null;
    this.DOBError = null;
    this.cellNumberError = null;
    this.CNICError = null;
    this.positionError = null;
    this.qualificationError = null;
    this.joiningDateError = null;
    this.usernameError = null;
    this.passwordError = null;
    this.addressStateError = null;
    this.addressDistrictError = null;
    this.addressCityError = null;
    this.addressL1Error = null;

    if(!this.mFirstName || this.mFirstName.length < 1) {
      this.firstNameError = 'Value is required';
      error = true;
    }
    if(!(this.mGender == 'm' || this.mGender == 'f')) {
      this.genderError = 'Value is required';
      error = true;
    }
    if(this.dateParserFormatter.format(this.mDOB).length != 10) {
      this.DOBError = 'Value is required';
      error = true;
    }

    if(this.mCellNumber.length != 9) {
      error = true;
      if(this.mCellNumber.length > 0) {
        this.cellNumberError = 'Invalid cell number';
      } else {
        this.cellNumberError = 'Value is required';
      }
    }

    if(this.mCNIC && this.mCNIC.length != 13) {
      error = true;
      this.CNICError = 'Invalid CNIC number ' + this.mCNIC.length;
    }

    if(!this.mPosition) {
      this.positionError  = 'Value is required';
      error = true;
    }

    if(!this.mQualification) {
      this.qualificationError  = 'Value is required';
      error = true;
    }

    if(this.dateParserFormatter.format(this.mJoiningDate).length != 10) {
      console.log("Joining Date: ", this.mJoiningDate);
      this.joiningDateError = 'Value is required';
      error = true;
    }
    if(!this.mUsername || this.mUsername.length < 5) {
      this.usernameError = 'Value must be at least 5 characters long.';
      error = true;
    }

    if(!this.mPassword || this.mPassword.length < 6) {
      this.passwordError = 'Value must be at least 6 characters long.';
      error = true;
    }
    if(!this.mAddressState) {
      this.addressStateError   = 'Value is required';
      error = true;
    }

    if(!this.mAddressDistrict) {
      this.addressDistrictError  = 'Value is required';
      error = true;
    }

    if(!this.mAddressCity) {
      this.addressCityError  = 'Value is required';
      error = true;
    }

    if(!this.mAddressL1) {
      this.addressL1Error  = 'Value is required';
      error = true;
    }

    if(error) {
      this.mainError = 'Please fix the errors and try again.';
      return;
    }

    let personalData = {
      first_name: this.mFirstName ? this.mFirstName : '',
      middle_name: this.mMiddleName ? this.mMiddleName : '',
      last_name: this.mLastName ? this.mLastName : '',
      gender: this.mGender ? this.mGender : '',
      date_of_birth: this.mDOB ? this.dateParserFormatter.format(this.mDOB) : ''
    };

    let contactData = {
      cell_number: this.mCellNumber ? '923' + this.mCellNumber : '',
      cnic_number: this.mCNIC ? this.mCNIC : '',
      email: this.mEmail ? this.mEmail : ''
    };

    let employmentData = {
      position: this.mPosition ? this.mPosition : '',
      designation: this.mDesignation ? this.mDesignation : '',
      qualification: this.mQualification ? this.mQualification : '',
      joining_date: this.mJoiningDate ? this.dateParserFormatter.format(this.mJoiningDate) : ''
    };

    let loginData = {
      username: this.mUsername ? this.mUsername : '',
      password: this.mPassword ? this.mPassword : ''
    };

    let addressData = {
      state: this.mAddressState ? this.mAddressState : '',
      district: this.mAddressDistrict ? this.mAddressDistrict : '',
      city: this.mAddressCity ? this.mAddressCity : '',
      line1: this.mAddressL1 ? this.mAddressL1 : '',
      line2: this.mAddressL2 ? this.mAddressL2 : ''
    };

    // console.log("Personal: ", personalData);
    // console.log("Contact: ", contactData);
    // console.log("Employment: ", employmentData);
    // console.log("Login: ", loginData);
    // console.log("Address: ", addressData);

    // return;
    Swal.showLoading();

    this.employeeService.addEmployee(
      personalData, contactData, employmentData, loginData, addressData
    ).subscribe(resp => {
      Swal.fire('Employee Added','', 'success')
        .finally(() => {
          this.router.navigate(['/admin/employee']);
        });
    }, error => {
      this.mainError = 'Please fix the errors and try again.';
      error = error.error;
      Swal.close();
      // console.error("CREATE ERROR: ", error.errors);
      for (let key in error.errors) {
        // console.log('KK', error.errors[key]);
        switch (key) {
          case 'first_name':
            this.firstNameError = this.getErrorString(error.errors[key]);
            break;
          case 'gender':
            this.genderError = this.getErrorString(error.errors[key]);
            break;
          case 'date_of_birth':
            this.DOBError = this.getErrorString(error.errors[key]);
            break;
          case 'cell_number':
            this.cellNumberError = this.getErrorString(error.errors[key]);
            break;
          case 'cnic_number':
            this.CNICError = this.getErrorString(error.errors[key]);
            break;
          case 'email':
            this.emailError = this.getErrorString(error.errors[key]);
            break;
          case 'joining_date':
            this.joiningDateError = this.getErrorString(error.errors[key]);
            break;
          case 'position':
            this.positionError = this.getErrorString(error.errors[key]);
            break;
          case 'qualification':
            this.qualificationError = this.getErrorString(error.errors[key]);
            break;
          case 'designation':
            this.designationError = this.getErrorString(error.errors[key]);
            break;
          case 'username':
            this.usernameError = this.getErrorString(error.errors[key]);
            break;
          case 'password':
            this.passwordError = this.getErrorString(error.errors[key]);
            break;
          case 'state':
            this.addressStateError = this.getErrorString(error.errors[key]);
            break;
          case 'district':
            this.addressDistrictError = this.getErrorString(error.errors[key]);
            break;
          case 'city':
            this.addressCityError = this.getErrorString(error.errors[key]);
            break;
          case 'line1':
            this.addressL1Error = this.getErrorString(error.errors[key]);
            break;
          default:
            this.mainError = 'An unexpected error has been encountered';
            break;
        }
      }
    });
  }

  getErrorString($err) {
    let $str = '';
    for (let i = 0; i < $err.length; i++) {
      $str = $str + $err[i];
      if(i != ($err.length - 1)) {
        $str = $str + '<br/>';
      }
    }
    return $err;
  }
}
