import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinanceRoutingModule } from './finance-routing.module';
import { HomeComponent } from './home/home.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AddAccountComponent } from './accounts/add-account/add-account.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ListItemComponent} from './accounts/list-item/list-item.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { StudentHomeComponent } from './students/student-home/student-home.component';
import { ClassroomSelectComponent } from './students/classroom/classroom-select/classroom-select.component';
import { SearchByRegnoComponent } from './students/search-by-regno/search-by-regno.component';
import { ClassroomChargeComponent } from './students/classroom/classroom-charge/classroom-charge.component';
import {ClassroomStudentsListComponent} from './students/classroom/classroom-students-list/classroom-students-list.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { ClassroomHomeComponent } from './students/classroom/classroom-home/classroom-home.component';
import { StudentTransactionsComponent } from './students/student-transactions/student-transactions.component';
import { StudentTransactionRecordComponent } from './students/student-transactions/student-transaction-record/student-transaction-record.component';
import { AddStudentTransactionComponent } from './students/student-transactions/add-student-transaction/add-student-transaction.component';
import { EmployeesComponent } from './employees/employees.component';
import { ListComponent } from './employees/tx/list/list.component';
import { EmployeeTransactionComponent } from './employees/tx/employee-transaction/employee-transaction.component';
import { AddTxComponent } from './employees/tx/employee-transaction/add-tx/add-tx.component';
import { TxItemComponent } from './employees/tx/employee-transaction/tx-item/tx-item.component';
import { EmployeeDetailsComponent } from './employees/tx/employee-details/employee-details.component';
import { NewEmployeeTransactionComponent } from './employees/tx/employee-details/new-employee-transaction/new-employee-transaction.component';
import { EmployeeTransactionRecordComponent } from './employees/tx/employee-details/employee-transaction-record/employee-transaction-record.component';

@NgModule({
  declarations: [
    HomeComponent,
    AccountsComponent,
    AddAccountComponent,
    ListItemComponent,
    StudentHomeComponent,
    ClassroomSelectComponent,
    SearchByRegnoComponent,
    ClassroomChargeComponent,
    ClassroomStudentsListComponent,
    ClassroomHomeComponent,
    StudentTransactionsComponent,
    StudentTransactionRecordComponent,
    AddStudentTransactionComponent,
    EmployeesComponent,
    ListComponent,
    EmployeeTransactionComponent,
    AddTxComponent,
    TxItemComponent,
    EmployeeDetailsComponent,
    NewEmployeeTransactionComponent,
    EmployeeTransactionRecordComponent
  ],
  imports: [
    CommonModule,
    FinanceRoutingModule,
    FormsModule,
    SweetAlert2Module,
    ReactiveFormsModule,
    NgbDatepickerModule
  ]
})
export class FinanceModule { }
