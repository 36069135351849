import { Component, OnInit } from '@angular/core';
import {Exam} from '../../../../Models/Exam/exam.model';
import {ExamService} from '../../services/exam.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  exams: Exam[];
  newStatus: boolean;
  constructor(
    private examService: ExamService
  ) { }

  ngOnInit() {
    this.newStatus = false;
    this.getExams();
  }

  getExams() {
    Swal.showLoading();
    this.examService.all()
      .subscribe(
        exams => {
          this.exams = exams;
          Swal.close();
        },
        error1 => {
          Swal.fire({
            title: error1.status + ': ' + error1.statusText,
            text: '',
            type: 'error'
          });
        }
      );
  }

  toggleNewStatus() {
    this.newStatus = !this.newStatus;
  }

}
