export class StudentTransaction {
  id: number;
  account_id: number;
  student_school_id: number;
  amount: number;
  amount_recieved: number;
  time_payment: string;
  status: string;
  created_at: string;
  updated_at: string;
}
