import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-new-session',
  templateUrl: './new-session.component.html',
  styleUrls: ['./new-session.component.css']
})
export class NewSessionComponent implements OnInit {

  title;
  startDate;
  endDate;
  @Output() cancelSession = new EventEmitter<boolean>();
  @Output() updateSessions = new EventEmitter<boolean>();
  constructor(
    private session: SessionService,
    private dateParser: NgbDateParserFormatter
    ) { }

  ngOnInit() {
  }
  addSession() {
    Swal.showLoading();
    this.session.createSession(
      this.title,
      this.dateParser.format(this.startDate),
      this.dateParser.format(this.endDate)
    ).subscribe( resp => {
      Swal.hideLoading();
      Swal.fire({
        title: resp.message,
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      });
    }, err => {
        Swal.hideLoading();
        Swal.fire(
          err.error.error,
          '',
          'error'
        );
        this.cancelSession.emit(false);
        this.updateSessions.emit(true);

    }
    );
  }

  // Cancel New Session.
  cancelNewSession() {
    this.title = '';
    this.startDate = '';
    this.endDate = '';
    this.cancelSession.emit(false);
  }
}
