import { Component, OnInit } from '@angular/core';
import {Classroom} from '../../../../Models/Classrooms/Classroom';
import {Exam} from '../../../../Models/Exam/exam.model';
import {AcademicSession} from '../../../sessions/models/AcademicSession';
import {SessionExam} from '../../../../Models/Exam/SessionExam';
import {Subject} from '../../../../Models/Subjects/Subject';
import {ActivatedRoute, Router} from '@angular/router';
import {ResultService} from '../../services/result.service';
import {SubjectService} from '../../../subjects/services/subject.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ResultModel} from '../Models/result.model';
import {map} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-subject-result',
  templateUrl: './add-subject-result.component.html',
  styleUrls: ['./add-subject-result.component.css']
})
export class AddSubjectResultComponent implements OnInit {


  classSubjectID: number;
  sessionExamID: number;

  classroom: Classroom;
  exam: Exam;
  session: AcademicSession;
  sessionExam: SessionExam;
  subject: Subject;
  students: ResultModel[];
  examDate;
  maxDate;
  totalMarks = 100;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resultService: ResultService,
    private subjectService: SubjectService,
    private dateParser: NgbDateParserFormatter
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.classSubjectID = params.class_subject_id;
      this.sessionExamID = params.session_exam_id;
      this.validateResult();
    });
    const date = new Date();
    this.examDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.maxDate = this.examDate;
  }

  getResult() {
    this.resultService.getResult(this.sessionExamID, this.classSubjectID)
      .subscribe(resp => {
        this.classroom = resp.classroom;
        this.exam = resp.exam;
        this.sessionExam = resp.session_exam;
        this.session = resp.session;
      });

    this.subjectService.getSubject(this.classSubjectID)
      .subscribe(subject => this.subject = subject);
  }

  getStudents() {
    this.resultService.getStudentList(this.sessionExamID, this.classSubjectID)
      .subscribe(resp => {
        map(this.students = resp);
        for (const student of this.students) {
          student.marks = 0;
          student.attendenceStatus = 'P';
        }
      });
  }

  addResult() {
    const parsedDate = this.dateParser.format(this.examDate) + ' ' + '00:00:00';
    const examDate = new Date(parsedDate).getTime();
    const today = Date.now();
    if (!examDate || examDate > today) {
      Swal.fire('Invalid Date',
        'Seems like you have selected an illegal date.',
        'error'
      );
      return;
    }
    if (!this.totalMarks) {
      Swal.fire('Enter valid total marks value.',
        '',
        'error'
      );
      return;
    }
    for (const student of this.students) {
      if (student.attendenceStatus == 'P' && student.marks > this.totalMarks) {
        Swal.fire('Student\'s marks cannot be greater than total marks.',
          'Please fix the highlighted fields. Marks obtained cannot be greater than total marks.',
          'error'
        );
        return;
      }
      if (student.attendenceStatus == 'P' && student.marks == null) {
        Swal.fire('Please enter a valid value for all present students.',
          '',
          'error');
        return;
      }
      if (student.attendenceStatus !== 'P') { student.marks = 0; }
    }
    // Validation complete
    Swal.showLoading();
    this.resultService.addSubjectResult(
      this.classSubjectID, this.sessionExamID,
      this.dateParser.format(this.examDate),
      this.totalMarks, this.students
    ).subscribe(
      resp => {
        console.log('Response: ', resp);
        if(resp.success && resp.success == true) {
          Swal.fire(resp.message,'','success')
            .finally(() => {
              this.toResultPage();
            });
        }
      }, error => {
        console.log('Error: ', error);
        if (error.status == 300) {
          Swal.fire('Result already announced.', '', 'error')
          // tslint:disable-next-line:only-arrow-functions
            .finally(() => {
              this.toResultPage();
            });
        }
      }
    );
  }

  validateResult() {
    this.resultService.validateResult(this.sessionExamID, this.classSubjectID)
      .subscribe(resp => {
        if (resp.result_announced && resp.result_announced == true) {
          this.toResultPage();
        } else {
          this.getResult();
          this.getStudents();
          Swal.close();
        }
      });
  }

  toResultPage() {
    this.router.navigate(['/admin/result',
      this.sessionExamID,
      this.classSubjectID]
    );
  }
}
