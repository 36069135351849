import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../Services/account.service';
import {StudentService} from '../../../student/services/student.service';
import Swal from 'sweetalert2';
import {StudentTransaction} from '../../Models/StudentTransaction';
import {Account} from '../../Models/Account';

@Component({
  selector: 'app-student-transactions',
  templateUrl: './student-transactions.component.html',
  styleUrls: ['./student-transactions.component.css']
})
export class StudentTransactionsComponent implements OnInit {

  registrationNumber: number;
  personal: any;
  enrollment: any;
  guardian: any;
  contact: any;
  transactions: StudentTransaction[];
  allAccounts: Account[];
  accounts: Account[];
  studentFound: boolean;
  newTxStatus = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private studentService: StudentService,
    private location: Location
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.registrationNumber = routeParams.studentRegNumber;
      this.getDetails();
    });
    this.getAllAccounts();
  }

  toggleNewTxStatus() {
    this.newTxStatus = !this.newTxStatus;
  }

  getDetails() {
    Swal.showLoading();
    this.studentService.studentDetails(this.registrationNumber)
      .subscribe(response => {
        Swal.close();
        this.getTransactions();
        this.studentFound = true;
        this.personal = response.data.personal;
        this.guardian = response.data.guardian;
        this.enrollment = response.data.enrollment;
        this.contact = response.data.contact;
      }, error1 => {
        this.studentFound = false;
        let errMsg = '';
        if (error1.error.success == false) {
          errMsg = error1.error.message;
        } else {
          errMsg = error1.status + ':' +  error1.statusText;
        }

        Swal.fire(errMsg, null, 'error').finally(() => {
          this.location.back();
        });
      });
  }

  getTransactions() {
    Swal.showLoading();
    this.accountService.getStudentTransactions(this.registrationNumber)
      .subscribe(resp => {
        this.accounts = resp.accounts;
        this.transactions = resp.transactions;
        console.log('Getting Transactions!');
        console.log('Accounts: ', this.accounts);
        console.log('Transactions: ', this.transactions);
        Swal.close();
      }, error1 => {
        this.studentFound = false;
        let errMsg = '';
        if (error1.error.success == false) {
          errMsg = error1.error.message;
        } else {
          errMsg = error1.status + ':' +  error1.statusText;
        }

        Swal.fire(errMsg, null, 'error').finally(() => {
          this.location.back();
        });
      });
  }

  getAccount(accoundID): Account {
    for (const account of this.accounts) {
      if (account.id == accoundID) { return account; }
    }
    return null;
  }

  getAllAccounts() {
    this.accountService.getAccounts().subscribe(
      accounts => {
        this.allAccounts = accounts;
        Swal.close();
      }
    );
  }

  deleteTransaction($event) {
    console.log('Event Value:', $event);
    this.transactions.forEach((value, index) => {
      if (value.id == $event) {
        this.transactions.splice(index, 1);
      }
    });
  }
}
