import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalValues} from '../../../../globals';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionExamService {

  private baseURI: string = GlobalValues.BASE_ENDPOINT_URL + '/sessionexam';
  constructor(
    private http: HttpClient
  ) { }

  all(): Observable<any> {
    return this.http.get(
      this.baseURI + '/all'
    );
  }

  add(exam_id: number): Observable<any> {
    return this.http.post(
      this.baseURI,
      {
        exam_id: exam_id
      }
    );
  }

  delete(session_exam_id: number): Observable<any> {
    return this.http.delete(
      this.baseURI + '/' + session_exam_id
    );
  }

}
