import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import * as swal from '@sweetalert2/ngx-sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class JwtAppInterceptorService implements HttpInterceptor{

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.includes('/admin/signin')) {}
    else if(req.url.includes('/admin/')) {
      const token = localStorage.getItem('access_token');
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
      req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    } else { }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (!(event instanceof HttpResponse)) {
          // console.log('event--->>>', event);
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }), // Map
    );//Pipe
  }

}
