import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Category} from '../../../Models/Subjects/Category';
import {SubjectService} from '../../subjects/services/subject.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-subject',
  templateUrl: './new-subject.component.html',
  styleUrls: ['./new-subject.component.css']
})
export class NewSubjectComponent implements OnInit {

  @Output() toggleNewSubjectState = new EventEmitter();
  @Output() refreshList = new EventEmitter();
  @Input() categories: Category[];
  mTitle: string = null;
  titleErrorMsg:string = null;
  mCategory:number = null;
  categoryErrorMsg:string = null;
  respError:string = null;

  constructor(
    private subjectService: SubjectService
  ) { }

  ngOnInit() {
  }

  cancelNewSubjectState() {
    this.toggleNewSubjectState.emit();
  }

  addBaseSubject() {
    this.titleErrorMsg = null;
    this.categoryErrorMsg = null;
    this.respError = null;
    if(this.mTitle.length < 2) {
      this.titleErrorMsg = "Title must be at least two characters long.";
      return;
    }
    if(!this.mCategory) {
      this.categoryErrorMsg = "Select a category.";
      return;
    }
    Swal.showLoading();
    this.subjectService.addBaseSubject(
      this.mTitle, this.mCategory
    ).subscribe(
      resp => {
        Swal.fire(resp.toString(),null,"success");
        this.refreshList.emit();
        this.toggleNewSubjectState.emit();
      }, err => {
        this.respError = err.error.error;
        Swal.fire(err.error.error, null,"error");
      }
    );
  }

}
