import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GlobalValues} from '../../../../globals';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import {Subject} from '../../../Models/Subjects/Subject';
import {Exam} from '../../../Models/Exam/exam.model';
import {AcademicSession} from '../../sessions/models/AcademicSession';
import {SessionExam} from '../../../Models/Exam/SessionExam';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  constructor(
    private http: HttpClient
  ) { }

  validateResult(sessionExamID, classSubjectID): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/result/validate/' + sessionExamID + '/' + classSubjectID
    );
  }

  getResult(sessionExamID, classSubjectID): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/result/subject/' + sessionExamID + '/' + classSubjectID
    );
  }

  getStudentList(sessionExamID, classSubjectID): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/result/studentlist/' + sessionExamID + '/' + classSubjectID
    );
  }

  addSubjectResult(
    classSubjectID, sessionExamID, examDate, totalMarks, resultData
  ): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/result/' + sessionExamID + '/' + classSubjectID,
      {
        class_subject_id: classSubjectID,
        session_exam_id: sessionExamID,
        exam_date: examDate,
        total_marks: totalMarks,
        result_data: resultData
      }
    );
  }

}
