import {Component, Input, OnInit} from '@angular/core';
import {StudentService} from "../../services/student.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";

@Component({
  selector: 'app-enrollment-info',
  templateUrl: './enrollment-info.component.html',
  styleUrls: ['./enrollment-info.component.css']
})
export class EnrollmentInfoComponent implements OnInit {

  @Input() enrollment: any;
  editStatus = false;
  mRegistrationNumber: string;
  mRollNumber;
  constructor(
    private studentService: StudentService,
    private router: Router
  ) { }

  ngOnInit() {
    console.log("Enrollment", this.enrollment);
    this.mRollNumber = this.enrollment.roll_number;
    this.mRegistrationNumber = this.enrollment.registration_number;
  }

  toggleEditStatus() {
    this.editStatus = !this.editStatus;
  }

  updateEnrollmentInfo() {
    console.log("Reg #: ", this.mRegistrationNumber);
    console.log("RollNumber: ", this.mRollNumber);
    console.log("Student School ID: ", this.enrollment.student_school_id);
    this.studentService.updateEnrollmentDetails(
      this.enrollment.registration_number, this.mRegistrationNumber, this.mRollNumber,
      this.enrollment.student_school_id
    ).subscribe(resp => {
      this.toggleEditStatus();
      if(resp.success && resp.success == true) {
        Swal.fire(resp.message, '', 'success');
        if(this.mRegistrationNumber !=  this.enrollment.registration_number) {
          this.router.navigate(['/admin/students/details', this.mRegistrationNumber]);
          return;
        }
      }
      if(resp.success && resp.success == false) {
        Swal.fire(resp.message, '', 'success');
        return;
      }
    }, error => {
      if(error.error && error.error.error) {
        error = error.error.error;
        Swal.fire(error.message,'', 'error');
      } else {
        Swal.fire(error.status + ': ' + error.statusText,'', 'error');
      }
      console.log("Error: ", error);
    });
  }
}
