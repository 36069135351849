import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../../../Models/User/Profile/Address/Address';

@Component({
  selector: 'app-address-component',
  templateUrl: './address-component.component.html',
  styleUrls: ['./address-component.component.css']
})
export class AddressComponentComponent implements OnInit {

  @Input() currentAddress: Address;
  @Input() permanentAddress: Address;
  constructor() { }

  ngOnInit() {
  }

  getAddress(addr: Address): string {
    let address = "";
    address += addr.line1 + ", " + addr.line2 + ", " + addr.zip_code;
    address += ", City " + addr.city.name + ", ";
    address += addr.city.district.name + ", ";
    address += addr.city.district.state.name + ", ";
    address += addr.city.district.state.country.name+".";
    return address;
  }

}
