import { Component, OnInit } from '@angular/core';
import {EmployeeService} from '../../../../employees/services/employee.service';
import {EmployeeListItem} from '../../../../employees/models/employee-list-item';
import {Account} from '../../../Models/Account';
import {AccountService} from '../../../Services/account.service';
import Swal from 'sweetalert2';
import {TransactionService} from "../../../Services/transaction.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  employees: any[];
  accounts: Account[];
  mAccoundID;
  mAccountIDError = false;
  mComment;
  constructor(
    private employeeService: EmployeeService,
    private accountService: AccountService,
    private transactionService: TransactionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getAccounts();
    this.getEmployees();
  }

  getAccounts() {
    this.accountService.getAccounts().subscribe(
      accounts => this.accounts = accounts,
      error => {
        console.log('Account Error: ', error);
      }
    );
  }
  getEmployees() {
    this.employeeService.getList().subscribe(
      employees => {
        this.employees = employees;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.employees.length; i++) {
          this.employees[i].amount = 0;
          this.employees[i].error = false;
        }
        // console.log('Employees', this.employees);
      },
      error => {
        console.log('Employee List Error', error);
      }
    );
  }
  addTransactions() {
    let error = false;
    if (!this.mAccoundID) {
      this.mAccountIDError = true;
      error = true;
    } else {
      this.mAccountIDError = false;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.employees.length; i++) {
      if (isNaN(this.employees[i].amount) || (this.employees[i].amount < 0)) {
        this.employees[i].error = true;
        error = true;
      } else {
        this.employees[i].error = false;
      }
    }
    if (error) {
      Swal.fire('Error!', 'Please review your request.', 'error');
      return;
    }
    Swal.showLoading();
    this.transactionService.addEmployeeListTransactions(
      this.mAccoundID, this.mComment, this.employees
    ).subscribe(resp => {
      Swal.fire(resp.message, '', 'success').then(result => {
        this.router.navigate(['/admin/finance/employees']);
      });
    }, error1 => {
      Swal.fire('Operation Failed!',
        'Couldn\'t complete your request. Please try again.',
        'error'
      );
    });

  }


}
