import { Component, OnInit } from '@angular/core';
import {ClassroomService} from '../../classrooms/services/classroom.service';
import {GradeService} from '../../grade/services/grade.service';
import {Grade} from '../../../Models/Classrooms/Grade';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import {State} from '../../../Models/User/Profile/Address/State';
import {District} from '../../../Models/User/Profile/Address/District';
import {AddressService} from '../../../Services/Address/address.service';
import {StudentService} from '../services/student.service';
import {GuardianRelation} from '../../../Models/Guardian/GuardianRelation';
import Swal from 'sweetalert2';
import {GuardianErrors} from '../../../Models/Student/Errors/AddStudent';
import {Router} from "@angular/router";
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enroll-new-student',
  templateUrl: './enroll-new-student.component.html',
  styleUrls: ['./enroll-new-student.component.css']
})
export class EnrollNewStudentComponent implements OnInit {

  constructor(
    private gradeServices: GradeService,
    private classroomService: ClassroomService,
    private addressService: AddressService,
    private studentService: StudentService,
    private router: Router
  ) { }
  grades: Grade[] = null;
  classrooms: Classroom[] = null;
  states: State[];
  districts: District[];
  guardianRelations: GuardianRelation[];
  error: boolean = false;
  // Personal Info
  profilePhoto:string;
  mFirstName: string;
  studentFirstNameError: string;
  mMiddleName: string;
  mLastName: string;
  mStudentGender: string;
  studentGenderError: string;
  mStudentDOB;
  studentDOB: string;
  studentDOBError: string;


  // Contact Details
  mStudentCellNumber: string;
  studentCellNumberError: string;
  mStudentEmail: string;
  studentEmailError: string;

  // Enrollment Details
  mStudentJoiningDate;
  studentJoiningDate: string;
  studentJoiningDateError: string = null;

  mStudentRegNumber: string = null;
  studentRegNumberError: string = null;

  mStudentGrade: string = null;
  studentGradeError: string = null;

  mStudentClassroom: string = null;
  studentClassroomError: string = null;

  mStudentRollNumber: string = null;
  studentRollNumberError: string = null;

  // Student Address
  mStudentAddressState: string = null;
  studentAddressStateError: string;
  mStudentAddressDistrict: string = null;
  studentAddressDistrictError: string;
  mStudentAddressCity: string = null;
  studentAddressCityError: string;
  mStudentAddressL1: string = null;
  studentAddressL1Error: string;
  mStudentAddressL2: string = null;
  studentAddressL2Error: string;

  // Guardian Details
  guardianExists: boolean = false;
  mGFirstName: string = null;
  GFirstNameError: string;
  mGMiddleName: string = null;
  mGLastName: string = null;
  mGRelation: string = null;
  GRelationError: string;
  mGRelationNote: string = null;
  mGCellNumber: string = null;
  GCellNumberError: string;
  mGEmail: string = null;
  GEmailError: string;
  guardian_errors: GuardianErrors;
  mainError: string = null;
  ngOnInit() {
    this.getGrades();
    this.getClassrooms();
    this.getStates();
    this.getDistricts();
    this.getGuardianRelations();
  }
  // Events
  onStateChange() {
    this.mStudentAddressDistrict = null;
  }
  onGradeChange() {
    this.mStudentClassroom = null;
  }

  //Initialization Methods
  getGrades() {
    this.gradeServices.getGrades().subscribe(
      resp => this.grades = <Grade[]>resp
    );
  }

  getClassrooms() {
    this.classroomService.getCurrent().subscribe(
      classrooms => this.classrooms = <Classroom[]> classrooms
    );
  }

  getStates() {
    this.addressService.getStates().subscribe(
      states => this.states = states,
      error1 => this.states = []
    )
  }
  getDistricts() {
    this.addressService.getDistricts().subscribe(
      districts => this.districts = districts,
      error1 => this.districts = []
    )
  }

  getGuardianRelations() {
    return this.studentService.getGuardianRelations().subscribe(
      relations => this.guardianRelations = relations,
      error1 => this.guardianRelations = []
    );

  }

  getDate(year:number, month:number, day:number) {
    let dayS = day < 10 ? "0" + day.toString() : day.toString();
    let monthS = month < 10 ? "0" + month.toString() : month.toString();
    let yearS = year.toString();
    return yearS+"-"+monthS+"-"+dayS;
  }

  updateProfilePic(imageBase64) {
    this.profilePhoto = imageBase64;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submit() {
    this.error = false;
    this.mainError = "";
    this.studentFirstNameError = null;
    this.studentGenderError = null;
    this.studentDOBError = null;
    this.studentCellNumberError = null;
    this.studentEmailError = null;
    this.studentCellNumberError = null;
    this.studentEmailError = null;
    this.studentJoiningDateError = null;
    this.studentRegNumberError = null;
    this.studentGradeError = null;
    this.studentClassroomError = null;
    this.studentRollNumberError = null;
    this.studentAddressStateError = null;
    this.studentAddressDistrictError = null;
    this.studentAddressCityError = null;
    this.studentAddressL1Error = null;

    this.guardian_errors = null;
    // this.guardian_errors.first_name = null;
    // this.guardian_errors.phone = null;
    // this.guardian_errors.email = null;
    // this.guardian_errors.relation_id = null;

    //Personal Info Validator
    if((!this.mFirstName) || this.mFirstName.length == 0) {
      this.studentFirstNameError = "Must be at least two characters long.";
      this.error = true;
    }

    if(!this.mStudentGender || this.mStudentGender.length != 1) {
      this.studentGenderError = "Value is required.";
      this.error = true;
    }

    if(!this.mStudentDOB) {
      this.studentDOBError = "Value is required.";
      this.error = true;
    } else {
      this.studentDOB = this.getDate(this.mStudentDOB.year, this.mStudentDOB.month, this.mStudentDOB.day);
      if(this.studentDOB.length != 10) {
        this.studentDOBError = "Invalid date.";
        this.error = true;
      }
    }

    //Contact Details
    this.mStudentCellNumber = "00923" + this.mStudentCellNumber;
    if(!(this.mStudentCellNumber.length == 5 || this.mStudentCellNumber.length == 14)) {
      this.studentCellNumberError = "Invalid cell number.";
      this.error = true;
    }

    if(this.mStudentEmail && !this.validateEmail(this.mStudentEmail)) {
      this.studentEmailError = "Invalid email";
      this.error = true;
    }
    // Enrollment Details
    if(!this.mStudentJoiningDate) {
      this.studentJoiningDateError = "Value is required.";
      this.error = true;
    } else {
      this.studentJoiningDate = this.getDate(this.mStudentJoiningDate.year, this.mStudentJoiningDate.month, this.mStudentJoiningDate.day);
      if(this.studentJoiningDate.length != 10) {
        this.studentJoiningDateError = "Invalid date.";
        this.error = true;
      }
    }


    if(!this.mStudentRegNumber || this.mStudentRegNumber.length < 1) {
      this.studentRegNumberError = "Value is required.";
      this.error = true;
    }

    if(!this.mStudentGrade) {
      this.studentGradeError = "Please select a valid value.";
      this.error = true;
    }

    if(!this.mStudentClassroom) {
      this.studentClassroomError = "Please select a valid value.";
      this.error = true;
    }

    if(!this.mStudentRollNumber || this.mStudentRollNumber.length < 1) {
      this.studentRollNumberError = "Value is required.";
      this.error = true;
    }

    // Address
    if(!this.mStudentAddressState || this.mStudentAddressState.length < 1) {
      console.log("State Error: ", this.mStudentAddressState);
      this.studentAddressStateError = "Please select a valid value.";
      this.error = true;
    }

    if(!this.mStudentAddressDistrict || this.mStudentAddressDistrict.length < 1) {
      console.log("District Error: ", this.mStudentAddressDistrict);
      this.studentAddressDistrictError = "Please select a valid value.";
      this.error = true;
    }

    if(!this.mStudentAddressCity || this.mStudentAddressCity.length < 1) {
      this.studentAddressCityError = "Value is required.";
      this.error = true;
    }

    if(!this.mStudentAddressL1 || this.mStudentAddressL1.length < 1) {
      this.studentAddressL1Error = "Value is required.";
      this.error = true;
    }

    // Guardian
    this.mGCellNumber = "00923" + this.mGCellNumber;
    if(this.mGCellNumber.length != 14) {
      this.GCellNumberError = "Invalid cell number.";
      this.error = true;
    }

    if((!this.guardianExists) &&
      (!this.mGFirstName || this.mGFirstName.length == 0)
    ) {
      this.guardian_errors.first_name[0] = "Value is required.";
      this.error = true;
    }


    if(this.mGEmail && !this.validateEmail(this.mGEmail)) {
      this.guardian_errors.email[0] = "Invalid email";
      this.error = true;
    }

    if(this.error) {
      this.mainError = "Please review the form again, resolve errors and try again.";
      // return;
    }

    let personalData: Object = {
      'first_name': this.mFirstName ? this.mFirstName : '',
      'middle_name' : this.mMiddleName ? this.mMiddleName : '',
      'last_name' : this.mLastName ? this.mLastName : '',
      'gender' : this.mStudentGender ? this.mStudentGender : '',
      'dob': this.studentDOB ? this.studentDOB : '',
      'email': this.mStudentEmail ? this.mStudentEmail : '',
      'cell_number': this.mStudentCellNumber ? this.mStudentCellNumber : ''
    };

    let enrollmentData: Object = {
      'joining_date': this.studentJoiningDate ? this.studentJoiningDate : '',
      'registration_number': this.mStudentRegNumber ? this.mStudentRegNumber : '',
      'grade_id': this.mStudentGrade ? this.mStudentGrade : '',
      'classroom_id': this.mStudentClassroom ? this.mStudentClassroom : '',
      'roll_number' : this.mStudentRollNumber ? this.mStudentRollNumber : ''
    };

    let addressData: Object = {
      'state_id': this.mStudentAddressState ? this.mStudentAddressState : '',
      'district_id': this.mStudentAddressDistrict ? this.mStudentAddressDistrict : '',
      'city': this.mStudentAddressCity ? this.mStudentAddressCity : '',
      'line1': this.mStudentAddressL1 ? this.mStudentAddressL1 : '',
      'line2': this.mStudentAddressL2 ? this.mStudentAddressL2 : ''
    };

    let guardianData: Object = {
      'exists': this.guardianExists ? this.guardianExists : false,
      'first_name': this.mGFirstName ? this.mGFirstName : '',
      'middle_name': this.mGMiddleName ? this.mGMiddleName : '',
      'last_name': this.mGLastName ? this.mGLastName : '',
      'phone': this.mGCellNumber ? this.mGCellNumber : '',
      'email': this.mGEmail ? this.mGEmail : '',
      'relation_id': this.mGRelation ? this.mGRelation : '',
      'relation_note': this.mGRelationNote ? this.mGRelationNote : '',
    };

    Swal.showLoading();
    this.studentService.addStudent(personalData,
      enrollmentData,
      addressData,
      guardianData
    ).subscribe(resp => {
        Swal.fire('Student Enrolled','Student has been successfully enrolled.','success');
        this.router.navigate(['admin/students']);
      },
      error1 => {
        Swal.close();
        if(error1.error.guardian_errors) {
          this.mainError = "Error Occured. Please Fix the errors and try again";
          this.guardian_errors = error1.error.guardian_errors;
        }

        // console.log("Fieldddd: ", error1.error.field);
        let error = error1.error;
        if(error1.error.field == 'validator') {
          this.mainError = "Error Occured. Please Fix the errors and try again";
          for (let key in error.errors) {
            console.log('KK' + key, error.errors[key]);
            switch (key) {
              case 'first_name':
                this.studentFirstNameError = this.getErrorString(error.errors[key]);
                break;
              case 'gender':
                this.studentGenderError = this.getErrorString(error.errors[key]);
                break;
              case 'dob':
                this.studentDOBError = this.getErrorString(error.errors[key]);
                break;
              case 'cell_number':
                this.studentCellNumberError = this.getErrorString(error.errors[key]);
                break;
              case 'email':
                this.studentEmailError = this.getErrorString(error.errors[key]);
                break;
              case 'joining_date':
                this.studentJoiningDateError = this.getErrorString(error.errors[key]);
                break;
              case 'classroom_id':
                this.studentClassroomError = this.getErrorString(error.errors[key]);
                break;
              case 'grade_id':
                this.studentGradeError = this.getErrorString(error.errors[key]);
                break;
              case 'registration_number':
                this.studentRegNumberError = this.getErrorString(error.errors[key]);
                break;
              case 'roll_number':
                this.studentRollNumberError = this.getErrorString(error.errors[key]);
                break;
              case 'state':
                this.studentAddressStateError = this.getErrorString(error.errors[key]);
                break;
              case 'district':
                this.studentAddressDistrictError = this.getErrorString(error.errors[key]);
                break;
              case 'city':
                this.studentAddressCityError = this.getErrorString(error.errors[key]);
                break;
              case 'line1':
                this.studentAddressL1Error = this.getErrorString(error.errors[key]);
                break;
              default:
                this.mainError = 'An unexpected error has been encountered';
                break;
            }
          }
        }
      }
      );

  } //Submit Ends
  getGuardianDetails() {
    this.GCellNumberError = "";
    this.guardianExists = false;
    this.mGFirstName = "";
    this.mGMiddleName = "";
    this.mGLastName = "";
    if(this.mGCellNumber.length != 9) {
      this.GCellNumberError = "Invalid cell Number";
      this.error = true;
      return;
    }
    Swal.showLoading();
    this.studentService.getUserByCellNumber("00923" + this.mGCellNumber)
      .subscribe(
        result => {
          console.log(result);
          if(result.success) {
            Swal.fire("Guardian Found.","",'success');
            this.guardianExists = true;
            this.mGFirstName = result.name.first_name;
            this.mGMiddleName = result.name.middle_name;
            this.mGLastName = result.name.last_name;
          } else {
            Swal.close();
          }
        }
      );
  }

  getErrorString($err) {
    let $str = '';
    for (let i = 0; i < $err.length; i++) {
      $str = $str + $err[i];
      if(i != ($err.length - 1)) {
        $str = $str + '<br/>';
      }
    }
    return $err;
  }
}
