import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../services/dashboard.service';
import {Dashboard} from '../models/dashboard.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboard: Dashboard;

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.get();
  }

  get() {
    Swal.showLoading();
    this.dashboardService.get()
      .subscribe(dash => {
        this.dashboard = dash;
        console.log(this.dashboard);
        Swal.close();
      });
  }



}
