import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalValues} from "../../../../globals";
import {Observable} from "rxjs";
import {StudentAttendance} from "../models/StudentAttendance";

@Injectable({
  providedIn: 'root'
})
export class StudentAttendanceService {

  constructor(private http: HttpClient) { }

  updateClassroomAttendance(classroomID, date, students): Observable<any> {
    let studentJSON = JSON.stringify(students);
    let attendanceData = JSON.parse(studentJSON);
    attendanceData.forEach(async (student, i) => {
      delete attendanceData[i].photo;
      delete attendanceData[i].full_name;
      delete attendanceData[i].guardian_name;
    });

    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/attendance/student/classroom/' + classroomID,
      {
        date,
        students: attendanceData
      }
    );
  }

  getClassroomAttendanceByDate(classroomID, date): Observable<StudentAttendance[]> {
    return this.http.get<StudentAttendance[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/attendance/student/classroom/' + classroomID + '/' + date
    );
  }

  getStudentAttendanceRange(registrationNumber, startDate, endDate):Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/attendance/student/range/'+ registrationNumber + '/' + startDate + '/' + endDate
    );
  }

  addAttendance(studentID, attendanceDate, attendanceStatus, attendanceReason): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/attendance/student/'+ studentID, {
        date: attendanceDate,
        status: attendanceStatus,
        reason: attendanceReason
      }
    );
  }

  updateAttendance(studentID, attendanceID, attendanceStatus, attendanceReason): Observable<any> {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/attendance/student/'+ studentID + '/' + attendanceID, {
        status: attendanceStatus,
        reason: attendanceReason
      }
    );
  }

  deleteAttendance(studentID, attendanceID): Observable<any> {
    return this.http.delete(
      GlobalValues.BASE_ENDPOINT_URL +
      '/attendance/student/'+ studentID + '/' + attendanceID
    );
  }

}
