import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassroomsRoutingModule } from './classrooms-routing.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtAppInterceptorService} from '../../Guards/services/jwt-app-interceptor.service';
import {FormsModule} from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { ClassroomComponent } from './home/classroom/classroom.component';
import { NewClassroomComponent } from './new-classroom/new-classroom.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { ClassroomListComponent } from './classroom-list/classroom-list.component';
import { StudentItemComponent } from './classroom-list/student-item/student-item.component';
import { DailyLessonsNotificationComponent } from './daily-lessons-notification/daily-lessons-notification.component';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HomeComponent,
    ClassroomComponent,
    NewClassroomComponent,
    ClassroomListComponent,
    StudentItemComponent,
    DailyLessonsNotificationComponent
  ],
  imports: [

    CommonModule,
    ClassroomsRoutingModule,
    FormsModule,
    SweetAlert2Module,
    NgbModule,
    NgbDatepickerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAppInterceptorService,
      multi: true
    },
  ]
})
export class ClassroomsModule { }
