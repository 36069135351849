import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Grade} from '../../../../Models/Classrooms/Grade';
import {GradeService} from '../../services/grade.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.css']
})
export class GradeComponent implements OnInit {
  @Input() grade: Grade;
  @Output() deleteGrade = new EventEmitter();
  editStatus = false;
  title = "";
  level;
  inputError = null;
  respError = null;
  constructor(
    private _gradeService: GradeService
  ) { }

  ngOnInit() {
  }

  updateEdit(status: boolean) {
    this.title = this.grade.grade;
    this.level = this.grade.level;
    this.editStatus = status;
  }

  updateGrade() {
    this.inputError = null;
    if(!this._gradeService.validateTitle(this.title)) {
      this.inputError = "Grade title must be at least two characters long";
      return
    }
    if(!this._gradeService.validateLevel(this.level)) {
      this.inputError = "Level must be a non negative number";
      return
    }
    if(this.level > 999999999) {
      this.inputError = "Level must be less than 999,999,999.";
      return
    }

    this._gradeService.updateGrade(this.grade.id, this.title, this.level).subscribe(
      resp => {
        this.grade.grade = resp[0];
        this.grade.level = resp[1];
        this.editStatus = false;
      }, err => {
        this.respError = err.error.error;
      }
    );

  }

  delete() {

    this._gradeService.deleteGrade(this.grade.id).subscribe(
      resp => {
        this.deleteGrade.emit(this.grade.id);
        Swal.fire("Deleted!","Grade deleted successfully.",'success');
      }, err => {
        Swal.fire("Error!",err.error.error,'error');
      }
    );
  }

}
