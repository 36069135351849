import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EmployeeService} from '../../../../employees/services/employee.service';
import {EmployeeInfo} from '../../../../employees/models/employee-info';
import {Image} from '../../../../../Models/User/Image';
import {EmployeeTransaction} from '../../../Models/EmployeeTransaction';
import Swal from "sweetalert2";
import {AccountService} from "../../../Services/account.service";
import {Account} from "../../../Models/Account";

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})
export class EmployeeDetailsComponent implements OnInit {
  employeeID;
  employeeInfo: EmployeeInfo;
  employeeDP: Image;
  dpResolution = 300;
  newTxStatus = false;
  transactions: EmployeeTransaction[];
  accounts: Account[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => {
        this.employeeID = params.employeeID;
        this.getEmployeeDetails();
        this.getEmployeeDP();
        this.getTransactions();
      }
    );
    this.getAccounts();
  }

  getEmployeeDetails() {
    this.employeeService.getInfo(this.employeeID)
      .subscribe(resp => {
        this.employeeInfo = resp;
      });
  }

  getEmployeeDP() {
    this.employeeService.dpImage(this.employeeID, this.dpResolution)
      .subscribe(resp => this.employeeDP = resp);
  }

  toggleNewTxStatus() {
    this.newTxStatus = !this.newTxStatus;
  }

  getTransactions() {
    this.employeeService.getFinancialtransaction(this.employeeID)
      .subscribe(transactions => this.transactions = transactions);
  }

  getAccounts() {
    this.accountService.getAccounts().subscribe(
      accounts => {
        this.accounts = accounts;
        Swal.close();
      }
    );
  }

  getAccount(accountID): Account {
    for (const account of this.accounts) {
      if (account.id == accountID) {
        return account;
      }
    }
    return null;
  }

  deleteTransaction($event) {
    this.transactions.forEach((value, index) => {
      if (value.id == $event) {
        this.transactions.splice(index, 1);
      }
    });
  }
}
