import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router } from '@angular/router';

@Component({
  selector: 'app-search-by-regno',
  templateUrl: './search-by-regno.component.html',
  styleUrls: ['./search-by-regno.component.css']
})
export class SearchByRegnoComponent implements OnInit {
  mRegistrationNumber: string = "";
  regnoError: string;
  @Output() refreshDetails = new EventEmitter();

  constructor(private router:Router) { }

  ngOnInit() {
  }

  searchByRegistrationNumber() {
    this.regnoError = "";
    if(this.mRegistrationNumber.toString().length < 1) {
      this.regnoError = "Please enter a valid registration number.";
      return;
    }
    this.refreshDetails.emit();
    this.router.navigate(['admin/students/details', this.mRegistrationNumber]);
  }
}
