import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { ImageCroppedEvent} from 'ngx-image-cropper';
import { ImageCropperComponent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.css']
})
export class ProfileImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  @Output() imageReady: EventEmitter<any> = new EventEmitter();
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageReady.emit(event.base64);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded')
  }
  cropperReady() {
    console.log('Cropper ready')
  }
  loadImageFailed () {
    Swal.fire("Image loading failed.","Please try again.","error");
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }
}
