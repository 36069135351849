import { Component, OnInit } from '@angular/core';
import {BaseSubject} from '../../../Models/Subjects/BaseSubject';
import {SubjectService} from '../../subjects/services/subject.service';
import {Category} from '../../../Models/Subjects/Category';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private subjectService: SubjectService
  ) { }

  categories: Category[];
  baseSubjects: BaseSubject[];
  newSubjectState: boolean = false;

  ngOnInit() {
    this.getCategories();
    this.getBaseSubjects();
  }

  getBaseSubjects() {
    this.subjectService.baseSubjects().subscribe(
      resp => this.baseSubjects = <BaseSubject[]> resp
    );
  }

  getCategories() {
    this.subjectService.categories().subscribe(
      categories => this.categories = <Category[]> categories
    );
  }

  getCategory(category_id:number): Category {
    return this.categories.filter(
      category => category.id == category_id
    )[0];
  }

  toggleNewSubjectState() {
    this.newSubjectState = !this.newSubjectState;
  }

}
