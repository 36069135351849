import { Component, OnInit } from '@angular/core';
import {EmployeeService} from '../services/employee.service';
import {EmployeeListItem} from '../models/employee-list-item';
import {ErrorHandlerService} from '../../../Services/error-handler.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  employees: EmployeeListItem[];


  constructor(
    private _employee: EmployeeService,
    private _errorHandler: ErrorHandlerService
    ) { }

  ngOnInit() {
    this.getEmployeesList();
  }
  getEmployeesList() {
    this._employee.getList().subscribe(
      // Response
      employees => {
        this.employees = employees
      },
      // Error
      err => {
        this._errorHandler.handlerHttpResponseError(err);
      });
  }
}
