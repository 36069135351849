import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {GradeService} from '../services/grade.service';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-new-grade',
  templateUrl: './new-grade.component.html',
  styleUrls: ['./new-grade.component.css']
})
export class NewGradeComponent implements OnInit {

  @Output() cancelNewGrade = new EventEmitter<boolean>();
  @Output() updateGrades = new EventEmitter();
  @ViewChild('SwalGradeAdded') private gradeAddedSwal: SwalComponent;

  title: string;
  level: number;
  titleErrorMsg: string = null;
  levelErrorMsg: string = null;
  respError: string = null;
  respSuccessMessage: string = "Grade added successfully.";

  constructor(
    private _gradeService: GradeService
  ) { }

  ngOnInit() {
    this.title = "";
  }

  cancelGrade() {
    this.cancelNewGrade.emit(false);
  }

  addGrade() {
    this.respError = null;
    if (!this._gradeService.validateTitle(this.title)) {
      this.titleErrorMsg = "" +
        "Grade title must be at least two characters long.";
      return;
    }
    this.titleErrorMsg = null;

    if (!this._gradeService.validateLevel(this.level)) {
      this.levelErrorMsg = "" +
        "Level must be a positive number.";
      return;
    }
    this.levelErrorMsg = null;

    this._gradeService.addGrade(this.title, this.level).subscribe(
      resp => {
        this.respSuccessMessage = resp.msg;
        this.updateGrades.emit(true);
        this.cancelNewGrade.emit(true);
        this.gradeAddedSwal.show();
      }, err => {
        this.respError = err.error.error;
      }
    );
  }
}
