import {Component, Input, OnInit} from '@angular/core';
import {NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {StudentService} from "../../services/student.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit {

  @Input() persoanl;
  @Input() guardian;
  @Input() regNumber;

  personalEditStatus;
  mFirstName: string;
  studentFNameError: string;
  mMiddleName: string;
  mLastName: string;
  mDOB;
  DOBError: string;
  maxDate;
  mGender: string;
  genderError: string;
  DPImage: any;
  constructor(
    private dateParserFormatter: NgbDateParserFormatter,
    private studentService: StudentService,
    private router: Router
  ) { }

  ngOnInit() {
    // console.log("Guardian: ", this.guardian);
    this.personalEditStatus = false;
    this.DPImage = this.persoanl.photo;
    this.mFirstName = this.persoanl.name.first_name;
    this.mMiddleName = this.persoanl.name.middle_name;
    this.mLastName = this.persoanl.name.last_name;
    this.mGender = this.persoanl.gender;
    this.mDOB = this.dateParserFormatter.parse(this.persoanl.dob);
    // Set Max Date to 1 year before
    let d = new Date();
    d.setFullYear(d.getFullYear() -1 );
    this.maxDate = this.dateParserFormatter.parse(d.toISOString().substring(0,10));
  }

  togglePersonalEditStatus() {
    this.personalEditStatus = !this.personalEditStatus;
  }

  updatePersonalInfo() {
    let error = false;
    this.studentFNameError = null;
    this.genderError = null;
    this.DOBError = null;

    if((!this.mFirstName) || this.mFirstName.length == 0) {
      this.studentFNameError = "Must be at least two characters long.";
      error = true;
    }

    if(!this.mGender) {
      // console.log("Gender: ", this.mGender);
      this.genderError = "Value is required.";
      error = true;
    }

    if(!this.mDOB) {
      this.DOBError = "Value is required.";
      error = true;
    } else {
      let studentDOB = this.dateParserFormatter.format(this.mDOB);
      // console.log("DOB", this.mDOB);
      if(studentDOB.length != 10) {
        this.DOBError = "Invalid date.";
        error = true;
      }
    }

    if(error) {
      return;
    }
    Swal.showLoading();
    this.studentService.updateInfo(
      this.regNumber, this.mFirstName, this.mMiddleName, this.mLastName,
      this.dateParserFormatter.format(this.mDOB), this.mGender
    ).subscribe( resp  => {
      Swal.fire(resp.message ? resp.message : 'Date Updated','', 'success');
      this.togglePersonalEditStatus();
    }, error => {
      if(error.error && error.error.error) {
        error = error.error.error;
        Swal.fire(error.message,'', 'error');
      } else {
        Swal.fire(error.status + ': ' + error.statusText,'', 'error');
      }
    });
  }

  getDate(year:number, month:number, day:number) {
    let dayS = day < 10 ? "0" + day.toString() : day.toString();
    let monthS = month < 10 ? "0" + month.toString() : month.toString();
    let yearS = year.toString();
    return yearS+"-"+monthS+"-"+dayS;
  }

  goToFinanceDetails() {
    this.router.navigate(['/admin/finance/students', this.regNumber]);
  }

}
