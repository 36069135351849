import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AfterLoginComponent} from '../../after-login/after-login.component';
import {AuthGuard} from '../../Guards/auth.guard';
import {HomeComponent} from './home/home.component';
import {AccountsComponent} from './accounts/accounts.component';
import {StudentHomeComponent} from './students/student-home/student-home.component';
import {ClassroomStudentsListComponent} from './students/classroom/classroom-students-list/classroom-students-list.component';
import {ClassroomHomeComponent} from './students/classroom/classroom-home/classroom-home.component';
import {StudentTransactionsComponent} from './students/student-transactions/student-transactions.component';
import {EmployeesComponent} from './employees/employees.component';
import {ListComponent} from './employees/tx/list/list.component';
import {EmployeeDetailsComponent} from './employees/tx/employee-details/employee-details.component';

const routes: Routes = [{
  path: 'admin/finance',
  component: AfterLoginComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent },
    {path: 'accounts', component: AccountsComponent },
    {path: 'students', children: [
        {path: '', component: StudentHomeComponent},
        {path: ':studentRegNumber', component: StudentTransactionsComponent},
        {path: 'classroom', children: [
            {path: ':classroomID', component: ClassroomHomeComponent},
            {path: ':classroomID/:accountID/:amount', component: ClassroomStudentsListComponent }
          ]},
      ]},
    {path: 'employees', children: [
        {path: '', component: EmployeesComponent },
        {path: 'tx/list', component: ListComponent },
        {path: 'tx/details/:employeeID', component: EmployeeDetailsComponent },
      ]
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinanceRoutingModule { }
