import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalValues } from '../../../../globals';
import {SchoolInfo} from '../models/SchoolInfo';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SchoolEmail} from '../models/SchoolEmail';
import {SchoolPhone} from '../models/SchoolPhone';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  access_token : string;
  constructor(private http: HttpClient) {
    // this.access_token = localStorage.getItem('access_token');
  }

  getInfo(): Observable<SchoolInfo> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/school/info'
    ).pipe(
      map(schoolInfo => <SchoolInfo>schoolInfo)
    );
  } // getInfo Ends

  getEmails(): Observable<SchoolEmail[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/school/emails'
    ).pipe(
      map(schoolEmails => <SchoolEmail[]>schoolEmails)
    );
  } // getEmails Ends

  getPhones(): Observable<SchoolPhone[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/school/phones'
    ).pipe(
      map(schoolPhones => <SchoolPhone[]>schoolPhones)
    );
  } // getPhones Ends
}
