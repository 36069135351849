import { Component, OnInit } from '@angular/core';
import {ClassroomService} from '../services/classroom.service';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import { Grade } from '../../../Models/Classrooms/Grade';
import {GradeService} from '../../grade/services/grade.service';
import {EmployeeListItem} from '../../employees/models/employee-list-item';
import {EmployeeService} from '../../employees/services/employee.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  classrooms: Classroom[];
  grades: Grade[];
  teachers: EmployeeListItem[];

  newClassroomStatus;

  constructor(
    private classroomService: ClassroomService,
    private gradeService: GradeService,
    private employeeService: EmployeeService
    ) { }

  ngOnInit() {
    this.getGrades();
    this.currentSessionClassrooms();
    this.newClassroomStatus = false;
    this.getEmployees();
  }
  toggleNewClassroomStatus() {
    this.newClassroomStatus = !this.newClassroomStatus;
  }


  currentSessionClassrooms() {
    return this.classroomService.getCurrent().subscribe(
      classrooms => this.classrooms = classrooms,
      error => {
        if(error.error && error.error.error) {
          error = error.error.error;
          Swal.fire(error,'', 'error');
        } else {
          Swal.fire(error.status + ': ' + error.statusText,'', 'error');
        }
      }
    );
  }

  getGrades() {
    return this.gradeService.getGrades().subscribe(
      grades => this.grades=grades
    );
  }

  getEmployees() {
    this.employeeService.getList().subscribe(
      // Response
      employees => this.teachers = employees
    );
  }

  grade(id: number): Grade {
    return this.grades.find(g => g.id == id);
  }
}
