import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountService} from '../../../../Services/account.service';
import {Account} from '../../../../Models/Account';
import {TransactionService} from '../../../../Services/transaction.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-new-employee-transaction',
  templateUrl: './new-employee-transaction.component.html',
  styleUrls: ['./new-employee-transaction.component.css']
})
export class NewEmployeeTransactionComponent implements OnInit {

  constructor(
    private transactionService: TransactionService
  ) { }

  @Input() accounts: Account[];
  @Input() employeeID: number;
  @Output() cancel = new EventEmitter<boolean>();
  @Output() txAdded = new EventEmitter<any>();

  mAccount: number;
  accountErr: string;
  mAmount: number;
  amountErr: string;
  mComment: string;

  ngOnInit() {
  }

  addTx() {
    this.accountErr = null;
    this.amountErr = null;
    if (!this.mAccount) {
      this.accountErr = 'Please select an account.';
      return;
    }
    if ((!this.mAmount) || (!(this.mAmount > 0))) {
      this.amountErr = 'Please enter an amount to charger.';
      return;
    }
    Swal.showLoading();
    this.transactionService.addEmployeeTransaction(
      this.employeeID, this.mAccount, this.mAmount, this.mComment
    ).subscribe(resp => {
      Swal.fire(resp.message, '', 'success');
      this.txAdded.emit({});
    });
  }

  cancelTx() {
    this.cancel.emit(true);
  }

}
