import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AuthService} from './login/services/auth.service';
declare function resetSideBar(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    ) {
  }

  ngOnInit() {
    if (this.authService.tokenExpired() && window.location.pathname != '/') {
      this.router.navigate(['/login']);
    }
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        resetSideBar();
        window.scrollTo(0, 0);
      });

  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
