import {Component, Input, OnInit} from '@angular/core';
import {Exam} from '../../../../Models/Exam/exam.model';
import {ExamService} from '../../services/exam.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {

  constructor(
    private examService: ExamService
  ) { }
  editStatus:boolean;
  mTitle: string;
  deleted: boolean;
  @Input() exam: Exam;

  ngOnInit() {
    this.mTitle = this.exam.title;
    this.editStatus = false;
    this.deleted = false;
  }

  toggleEditStatus(){
    this.editStatus = !this.editStatus;
  }

  updateExam(){
    Swal.showLoading();
    if(this.mTitle.length < 1) {
      Swal.fire("Exam title is required.");
    }
    this.examService.update(this.exam.id, this.mTitle)
      .subscribe(resp => {
        Swal.fire(resp.message,'','success');
        this.toggleEditStatus();
        this.exam.title = this.mTitle;
      },
        error1 => {
          let errorMessage = "";
          if(error1.error.error.exam_title) {
            errorMessage = error1.error.error.exam_title;
          } else if(error1.error.error.exam_id) {
            errorMessage = error1.error.error.exam_id;
          } else {
            errorMessage = error1.status +": " +error1.statusText;
          }
          Swal.fire(errorMessage,'','error');
        });
  }

  deleteExam() {
    Swal.showLoading();
    this.examService.delete(this.exam.id).subscribe(
      resp => {
        Swal.fire(resp.message,'','success');
        this.deleted = true;
      }, error1 => {
        let errorMessage = "";
        if(error1.error.error.exam_id) {
          errorMessage = error1.error.error.exam_id;
        } else {
          errorMessage = error1.status +": " +error1.statusText;
        }
        Swal.fire(errorMessage,'','error');
      }
    );
  }

}
