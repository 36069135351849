import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AccountService} from '../../Services/account.service';
import Swal from 'sweetalert2';
import {Account} from "../../Models/Account";

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  @Output() cancelAccount = new EventEmitter();
  @Output() accountAdded = new EventEmitter();
  accountTitle: string;
  accountType: string;
  // frequency: string;
  accountTitleError: any;
  accountTypeError: any;
  account: Account;

  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
  }

  cancelNewAccount() {
    this.cancelAccount.emit();
  }

  addAccount() {
    Swal.showLoading();
    this.accountTitleError = null;
    this.accountTypeError = null;
    this.accountService.addAccount(this.accountTitle, this.accountType)
      .subscribe((account) => {
        this.account = account;
        console.log('ACC', this.account);
        this.accountAdded.emit(account);
        Swal.close();
      }, error => {
        error = error.error;
        if (error.account_title) {
          this.accountTitleError = error.account_title;
        }

        if (error.account_type) {
          this.accountTypeError = error.account_type;
        }
        Swal.close();
      });
  }
}
