import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradeRoutingModule } from './grade-routing.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtAppInterceptorService} from '../../Guards/services/jwt-app-interceptor.service';
import { HomeComponent } from './home/home.component';
import { GradeComponent } from './home/grade/grade.component';
import { NewGradeComponent } from './new-grade/new-grade.component';
import {FormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [HomeComponent, GradeComponent, NewGradeComponent],
  imports: [
    CommonModule,
    GradeRoutingModule,
    FormsModule,
    SweetAlert2Module
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAppInterceptorService,
      multi: true
    },
  ]
})
export class GradeModule { }
