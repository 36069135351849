import {Component, Input, OnInit} from '@angular/core';
import Swal, {SweetAlertResult} from "sweetalert2";
import {StudentAttendanceService} from "../../../services/student-attendance.service";

@Component({
  selector: 'app-attendance-day',
  templateUrl: './attendance-day.component.html',
  styleUrls: ['./attendance-day.component.css']
})
export class AttendanceDayComponent implements OnInit {

  @Input() attendanceDate;
  @Input() attendance;
  @Input() studentID: number;
  attendanceOptions = {
    'PRESENT': 'Present',
    'ABSENT': 'Absent',
    'LEFT EARLY': 'Left Early',
    'LEAVE': 'Leave',
    'LATE': 'Late'
  };
  constructor(
    private attendanceService: StudentAttendanceService
  ) { }

  ngOnInit() {
  }

  getDate() {
    let d = new Date(this.attendanceDate);
    return d.getDate();
  }

  getStatus() {
    if(this.attendance == null) {
      return null;
    }

    return this.attendance.status;
  }

  editAttendance() {
    let attendanceUpdate = {
      status: this.attendance == null ? 'PRESENT' : this.attendance.status,
      reason: this.attendance == null ? '' : this.attendance.reason,
    };
    Swal.fire({
      title: 'Attendance Status',
      input: 'select',
      inputOptions: this.attendanceOptions,
      inputClass: 'form-control',
      inputValue: attendanceUpdate.status,
      showCancelButton: true,
      confirmButtonText: 'Update'
    }).then(status => {
      if(!status.dismiss) {
        console.log("SWAL Statussss: ", status);
        attendanceUpdate.status = status.value;
        if (status.value == 'PRESENT') {
          if (this.attendance == null) {
            this.addAttendance(attendanceUpdate.status, attendanceUpdate.reason);
          } else {
            this.updateAttendance(attendanceUpdate.status, attendanceUpdate.reason);
          }
        } else {
          Swal.fire({
            title: 'Reason or Comment',
            input: 'text',
            inputClass: 'form-control',
            inputValue: attendanceUpdate.reason,
            showCancelButton: true,
            confirmButtonText: 'Update'
          }).then(reason => {
            if(reason.value) {
              attendanceUpdate.reason = reason.value;
              if (this.attendance == null) {
                this.addAttendance(attendanceUpdate.status, attendanceUpdate.reason);
              } else {
                this.updateAttendance(attendanceUpdate.status, attendanceUpdate.reason);
              }
            }
          });
        }
      }
    }).catch((err) => {
      Swal.fire({
        title: 'Error!',
        text: 'An unexpected error has been encountered. Please try again.',
        type: 'error'
      })
    });
  }//editAttendance Ends

  addAttendance(status, reason) {
    Swal.close();
    Swal.showLoading();
    this.attendanceService.addAttendance(this.studentID, this.attendanceDate, status, reason)
      .subscribe(resp => {
        this.attendance = resp.data;
        Swal.fire('Data Updated!','','success');
      }, error => {
        let errMsg = 'Operation Failed!';
        if(error.error && error.error.message) {
          errMsg = error.error.message;
        }
        Swal.fire(errMsg,'Please try again.','error');
      });
  }

  updateAttendance(status, reason) {
    Swal.close();
    Swal.showLoading();
    this.attendanceService.updateAttendance(this.studentID, this.attendance.id, status, reason)
      .subscribe(resp => {
        this.attendance = resp.data;
        Swal.fire('Data Updated!','','success');
      }, error => {
        let errMsg = 'Operation Failed!';
        if(error.error && error.error.message) {
          errMsg = error.error.message;
        }
        Swal.fire(errMsg,'Please try again.','error');
      });
  }

  deleteAttendance() {
    this.attendanceService.deleteAttendance(this.studentID, this.attendance.id)
      .subscribe(resp => {
        Swal.fire('Recorded Deleted!','', 'success');
        this.attendance = null;
      }, error => {
        Swal.fire('Error!', 'An error has been encountered. Please try again', 'error');
      });
  }
}
