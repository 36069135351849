import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {StudentAttendanceService} from "../../services/student-attendance.service";

@Component({
  selector: 'app-classroom-attendance-view-range',
  templateUrl: './classroom-attendance-view-range.component.html',
  styleUrls: ['./classroom-attendance-view-range.component.css']
})
export class ClassroomAttendanceViewRangeComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private studentAttendanceService: StudentAttendanceService
  ) { }

  registrationNumber: string;
  startDate: string;
  endDate: string;

  info: any;
  attendanceData: any;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.registrationNumber = params.get('registrationNumber');
      this.startDate = params.get('startDate');
      this.endDate = params.get('endDate');
      // console.log(this.registrationNumber, this.startDate, this.endDate);
      this.getAttendance();
    });
  }

  getAttendance() {
    return this.studentAttendanceService.getStudentAttendanceRange(
      this.registrationNumber, this.startDate, this.endDate
    ).subscribe(resp => {
      console.log("Attendance Data:", resp);
      this.attendanceData = resp.attendance;
      this.info = resp.student;
      console.log(this.attendanceData);

    });

  }

  public keepOriginalOrder = (a, b) => a.key;

  //Date Functions
  getDay(dateString) {
    let d = new Date(dateString);
    return d.getDay();
  }

  getDate(dateString) {
    let d = new Date(dateString);
    return d.getDate();
  }

  emptyArray(n: number): any[] {
    if(n < 0) n = 6
    return Array(n);
  }


}
