import { Component, OnInit } from '@angular/core';
import {EmployeeService} from '../../employees/services/employee.service';
import {EmployeeListItem} from '../../employees/models/employee-list-item';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {

  constructor(
    private employeeService: EmployeeService
  ) { }

  employees: EmployeeListItem[];
  hoverBgClassEmp = '';
  hoverBgClassStu = '';

  ngOnInit() {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getList().subscribe(
      employees => {
        this.employees = employees;
        // console.log('Employees', this.employees);
      },
      error => {
        console.log('Employee List Error', error);
      }
    );
  }

}
