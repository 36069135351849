import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ClassroomDetails } from "../../../classrooms/classroom-list/ClassroomDetails";
import { ClassroomService } from "../../../classrooms/services/classroom.service";
import Swal from "sweetalert2";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { StudentAttendanceService } from "../../services/student-attendance.service";

@Component({
  selector: 'app-classroom-attendance-input',
  templateUrl: './classroom-attendance-input.component.html',
  styleUrls: ['./classroom-attendance-input.component.css']
})
export class ClassroomAttendanceInputComponent implements OnInit {
  classroomID: number;
  attendanceStatus = ['PRESENT', 'ABSENT', 'LEFT EARLY', 'LEAVE', 'LATE'];
  classroom: ClassroomDetails;
  students: any[];
  attendanceDate;
  dateError: string;
  studentsErrorStatus = false;
  studentsError: any[];


  constructor(
    private activatedRoute: ActivatedRoute,
    private classroomService: ClassroomService,
    private dateParserFormatter: NgbDateParserFormatter,
    private studentAttendanceService: StudentAttendanceService
  ) { }

  ngOnInit() {
    Swal.showLoading();
    this.activatedRoute.paramMap.subscribe(params => {
      this.classroomID = parseInt(params.get('classroomID'));
      this.getClassroomDetails();
    });
    let d = new Date();
    this.attendanceDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
  }

  getClassroomDetails() {
    this.classroomService.getDetails(this.classroomID)
      .subscribe(classroom => {
        this.students = classroom.students;
        console.log(this.students);
        for (let i=0; i < this.students.length; i++) {
          this.students[i].attendance_status = 'PRESENT';
          this.students[i].attendance_reason = '';
        }
        this.classroom = classroom;
        Swal.close();
      });
  }

  updateAttendance() {
    Swal.showLoading();
    this.studentsError = [];
    this.studentsErrorStatus = false;
    this.dateError = null;

    this.studentAttendanceService.updateClassroomAttendance(
      this.classroomID, this.dateParserFormatter.format(this.attendanceDate), this.students
    ).subscribe(
      resp => {
        Swal.fire(resp.message, "", 'success');
        console.log("Response: ", resp);
        console.log("Students: ", this.students);
      },
        error => {
        console.log(error);
        if(error.error && error.error.success == false) {
          if(error.error.field == 'students') {
            // Students data error
            Swal.fire(error.error.message, '', 'error');
            this.studentsError = error.error.data;
            this.studentsErrorStatus = true;
          } else if(error.error.field == 'date') {
            Swal.fire(error.error.message, '', 'error');
            this.dateError = error.error.message;
          } else if(error.error.field == 'main') {
            Swal.fire(error.error.message, '', 'error');
          } else {
            Swal.fire(
              'Error!',
              'An unexpected error has been encountered. Please try again.',
              'error'
            );
          }
        } else {
          Swal.fire(
            'Error!',
            'An unexpected error has been encountered. Please try again.',
            'error'
          );
        }
      }
    );
  }

  getStudentError (studentID) {
    if(!this.studentsErrorStatus) { return false; }
    for(let studentErr of this.studentsError) {
      if(studentErr.student_id == studentID) {
        return studentErr.error;
      }
    }
    return false;
  }
}
