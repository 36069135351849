import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import {ExamService} from '../../services/exam.service';

@Component({
  selector: 'app-create-exam',
  templateUrl: './create-exam.component.html',
  styleUrls: ['./create-exam.component.css']
})
export class CreateExamComponent implements OnInit {

  @Output() cancel = new EventEmitter();
  @Output() refresh = new EventEmitter();

  constructor(
    private examService: ExamService
  ) { }

  ngOnInit() {
  }

  mTitle: string;

  createExam() {
    Swal.showLoading();
    this.examService.create(this.mTitle).subscribe(
      resp => {
        Swal.fire({
          title : resp.message,
          type: 'success',
          text: '',
          onClose: () => {
            this.refresh.emit();
            this.cancel.emit();
          }
        });
      }, error1 => {
        let errorMessage = "";
        if(error1.error.error.exam_title) {
          errorMessage = error1.error.error.exam_title;
        } else {
          errorMessage = error1.status +": " +error1.statusText;
        }
        Swal.fire(errorMessage,'','error');
      }
    );
  }
}
