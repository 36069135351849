@Component({
  selector: 'app-sidebar',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor() { }

  // this.activatedRoute
  // setTimeout(function () {

//         function expand($listItem, callback) {
//           var $subList = $listItem.children('ul');
//           var $children = $subList.children('li').addClass('is-hidden');
//
//           $listItem.addClass('open');
//
//           $subList.hide().slideDown(200, function() {
//             $(this).css('display', '');
//
//             if (callback) {
//               callback();
//             }
//           });
//
//
//
//           setTimeout(function() {
//             $children.addClass('is-shown');
//             $children.removeClass('is-hidden');
//           }, 0);
//         }
//
//         $('.logo-text').on('click',function(){
//
//           var listItem = $sidebar_content.find('li.open.has-sub'),
//             activeItem = $sidebar_content.find('li.active');
//
//           if(listItem.hasClass('has-sub') && listItem.hasClass('open')){
//             collapse(listItem);
//             listItem.removeClass('open');
//             if(activeItem.closest('li.has-sub')){
//               openItem = activeItem.closest('li.has-sub');
//               expand(openItem);
//               openItem.addClass('open');
//             }
//           }
//           else{
//             if(activeItem.closest('li.has-sub')){
//               openItem = activeItem.closest('li.has-sub');
//               expand(openItem);
//               openItem.addClass('open');
//             }
//           }
//         });
//
//
//         $('.nav-toggle').on('click',function(){
//           var $this = $(this),
//             toggle_icon= $this.find('.toggle-icon'),
//             toggle = toggle_icon.attr('data-toggle'),
//             compact_menu_checkbox = $('.cz-compact-menu');
//
//           if(toggle === 'expanded'){
//             $wrapper.addClass('nav-collapsed');
//
//             $('.nav-toggle').find('.toggle-icon').removeClass('ft-disc').addClass('ft-circle');
//             toggle_icon.attr('data-toggle', 'collapsed');
//             if(compact_menu_checkbox.length > 0){
//               compact_menu_checkbox.prop('checked',true);
//             }
//           }
//           else{
//             $wrapper.removeClass('nav-collapsed menu-collapsed');
//
//             $('.nav-toggle').find('.toggle-icon').removeClass('ft-circle').addClass('ft-disc');
//             toggle_icon.attr('data-toggle', 'expanded');
//             if(compact_menu_checkbox.length > 0){
//               compact_menu_checkbox.prop('checked',false);
//             }
//           }
//         });
//
//         $sidebar.on('mouseenter', function() {
//           if($wrapper.hasClass('nav-collapsed')){
//             $wrapper.removeClass('menu-collapsed');
//             var $listItem = $('.navigation li.nav-collapsed-open'),
//               $subList = $listItem.children('ul');
//
//             $subList.hide().slideDown(300, function() {
//               $(this).css('display', '');
//             });
//
//             $sidebar_content.find('li.active').parents('li').addClass('open');
//             $listItem.addClass('open').removeClass('nav-collapsed-open');
//           }
//         }).on('mouseleave', function(event) {
//           if($wrapper.hasClass('nav-collapsed')){
//             $wrapper.addClass('menu-collapsed');
//             var $listItem = $('.navigation li.open'),
//               $subList = $listItem.children('ul');
//             $listItem.addClass('nav-collapsed-open');
//
//             $subList.show().slideUp(300, function() {
//               $(this).css('display', '');
//             });
//
//             $listItem.removeClass('open');
//           }
//         });
//
//         if ($(window).width() < 992) {
//           $sidebar.addClass('hide-sidebar');
//           $wrapper.removeClass('nav-collapsed menu-collapsed');
//         }
//         $( window ).resize(function() {
//           if ($(window).width() < 992) {
//             $sidebar.addClass('hide-sidebar');
//             $wrapper.removeClass('nav-collapsed menu-collapsed');
//           }
//           if ($(window).width() > 992) {
//             $sidebar.removeClass('hide-sidebar');
//             if( $('.toggle-icon').attr('data-toggle') === 'collapsed' &&  $wrapper.not('.nav-collapsed menu-collapsed')){
//               $wrapper.addClass('nav-collapsed menu-collapsed');
//             }
//           }
//         });
//
//         $(document).on('click', '.navigation li:not(.has-sub)', function(){
//           if( $(window).width() < 992 ){
//             $sidebar.addClass('hide-sidebar');
//           }
//         });
//
//         $(document).on('click', '.logo-text', function(){
//           if( $(window).width() < 992 ){
//             $sidebar.addClass('hide-sidebar');
//           }
//         });
//
//
//         $('.navbar-toggle').on('click',function(e){
//           e.stopPropagation();
//           $sidebar.toggleClass('hide-sidebar');
//         });
//
//         $('html').on('click', function (e) {
//           if ($(window).width() < 992) {
//             if (!$sidebar.hasClass('hide-sidebar') && $sidebar.has(e.target).length === 0) {
//               $sidebar.addClass('hide-sidebar');
//             }
//           }
//         });
//
//         $('#sidebarClose').on('click', function(){
//           $sidebar.addClass('hide-sidebar');
//         });
//
//         $('.noti-list').perfectScrollbar();
//
//
//         // Page full screen
//         $('.apptogglefullscreen').on('click', function(e) {
//           if (typeof screenfull != 'undefined'){
//             if (screenfull.enabled) {
//               screenfull.toggle();
//             }
//           }
//         });
//         if (typeof screenfull != 'undefined'){
//           if (screenfull.enabled) {
//             $(document).on(screenfull.raw.fullscreenchange, function(){
//               if(screenfull.isFullscreen){
//                 $('.apptogglefullscreen').find('i').toggleClass('ft-minimize ft-maximize');
//               }
//               else{
//                 $('.apptogglefullscreen').find('i').toggleClass('ft-maximize ft-minimize');
//               }
//             });
//           }
//         }
//       });
//     })(window, document, jQuery);
//   }
// ,0);
  ngOnInit() {
    // this.router.events.subscribe(event => {
    //   this.sidebar();
    // });
  }

}

import { Component, OnInit } from '@angular/core';
