import {Component, Input, OnInit} from '@angular/core';
import {AcademicSession} from '../../models/AcademicSession';

@Component({
  selector: 'app-session-item',
  templateUrl: './session-item.component.html',
  styleUrls: ['./session-item.component.css']
})
export class SessionItemComponent implements OnInit {

  @Input() Session : AcademicSession;
  constructor() { }

  ngOnInit() {
  }

}
