import {Component, Input, OnInit} from '@angular/core';
import {EmployeeInfo} from '../../models/employee-info';
import {EmployeeService} from '../../services/employee.service';
import {Image} from '../../../../Models/User/Image';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit {
  @Input() employeeID: number;
  employeeInfo: EmployeeInfo;
  DPImage: Image;

  constructor(private _employee: EmployeeService) { }

  ngOnInit() {
    this.updateInfo();
  }

  updateInfo() {
    this._employee.getInfo(this.employeeID).subscribe(
      info => this.employeeInfo = info
    );
    this.getDP(480);
  }
  getDP(resolution) {
    if(!resolution) { resolution = 480; }
    return this._employee.dpImage(this.employeeID, resolution).subscribe(
      dp => {
        this.DPImage = dp;
      }
    );
  }
}
