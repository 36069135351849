import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfterLoginComponent } from '../../after-login/after-login.component';
import { AuthGuard } from '../../Guards/auth.guard';
import {EmployeeListComponent} from './employee-list/employee-list.component';
import {EmployeeDetailsComponent} from './employee-details/employee-details.component';
import {AddEmployeeComponent} from "./add-employee/add-employee.component";


const employeeRoutes: Routes = [
  {
    'path' : 'admin/employee',
    component: AfterLoginComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '',             component: EmployeeListComponent },
      { path: 'add',          component: AddEmployeeComponent },
      { path: ':employeeID',  component: EmployeeDetailsComponent },
    ]
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(employeeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmployeeRoutingModule { }
