import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  constructor(private http: HttpClient) { }

  getCurrent(): Observable<Classroom[]> {
    return this.http.get<Classroom[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/classrooms'
    ).pipe(
      map(classrooms => classrooms as Classroom[])
    );
  }

  getDetails(classroomID): Observable<any> {
    return this.http.get<any>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/classrooms/' + classroomID
    ).pipe(
      map(resp => resp)
    );
  }
  getSessionClassrooms(sessionID: number): Observable<Classroom[]> {
    return this.http.get<Classroom[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/classrooms/session/' + sessionID
    ).pipe(
      map(classrooms => classrooms as Classroom[])
    );
  }

  addClassroom(title: string,
               gradeID: number,
               teacherID: number): Observable<any> {

      return this.http.post(
        GlobalValues.BASE_ENDPOINT_URL +
        '/classrooms', {
          title,
          grade_id: gradeID,
          teacher_id: teacherID
        }
      ).pipe(
        resp => {
          return resp;
        }
      );
  } // Add Classroom Ends

  updateClasstoom(classroomID: number,
                  title: string,
                  gradeID: number,
                  teacherID: number): Observable<any> {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/classrooms/' + classroomID, {
        title,
        grade_id: gradeID,
        teacher_id: teacherID
      }
    ).pipe(
      resp => {
        return resp;
      }
    );
  }

  deleteClassroom(classroomID: number): Observable<any> {
    return this.http.delete(
      GlobalValues.BASE_ENDPOINT_URL +
      '/classrooms/' + classroomID
    ).pipe(
      resp => {
        return resp;
      }
    );
  }

}
