import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AfterLoginComponent} from "./after-login/after-login.component";
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from './Guards/auth.guard';
import {InfoComponent} from './modules/school/info/info.component';
import {HomepageComponent} from "./homepage/homepage.component";
import {DashboardComponent} from './modules/home/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { // Guardian Routes comes after login, will be guarded
    path: 'admin',
    component: AfterLoginComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'home', component: DashboardComponent },
      // { path: 'school', component: InfoComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
