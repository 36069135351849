import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import {SessionsRoutingModule} from './sessions-routing.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtAppInterceptorService} from '../../Guards/services/jwt-app-interceptor.service';
import { SessionItemComponent } from './list/session-item/session-item.component';
import {SessionService} from './services/session.service';
import { CurrentSessionComponent } from './list/current-session/current-session.component';
import { NewSessionComponent } from './list/new-session/new-session.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ListComponent,
    SessionItemComponent,
    CurrentSessionComponent,
    NewSessionComponent,
  ],
  imports: [
    CommonModule,
    SessionsRoutingModule,
    SweetAlert2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
  ],
  exports: [
    ListComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAppInterceptorService,
      multi: true
    },
    SessionService,
  ]
})
export class SessionsModule { }
