import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ClassroomDetails} from './ClassroomDetails';
import {StudentItem} from './StudentItem';
import {ClassroomService} from '../services/classroom.service';
import {map} from 'rxjs/operators';
import {AccountService} from '../../finance/Services/account.service';

@Component({
  selector: 'app-classroom-list',
  templateUrl: './classroom-list.component.html',
  styleUrls: ['./classroom-list.component.css']
})
export class ClassroomListComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private classroomService: ClassroomService,
    private accountService: AccountService,
  ) { }
  classroomId: number = null;

  classDetails: ClassroomDetails;
  students: StudentItem[];
  summary: any;

  ngOnInit() {
    // Get Classroom ID from parameter
    this.route.paramMap.subscribe(params => {
      this.classroomId = parseInt(params.get('id'));
      if(!this.classroomId){ // ID not valid
        // TODO
      }
      this.fetchDetails(this.classroomId);
      this.getClassSummary(this.classroomId);
    });
  }

  fetchDetails(classroomID) {
    this.classroomService.getDetails(classroomID).subscribe(
      resp => {
        this.students = resp.students;
        this.classDetails = resp;
        //
        // console.log('Students: ', this.students);
        // console.log('Details: ', this.classDetails);
      }
    );
  }

  getClassSummary(classroomID) {
    this.accountService.classroomSummary(classroomID)
      .subscribe(resp => {
        this.summary = resp;
      });
  }

}
