import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';
import {EmployeeListItem} from '../models/employee-list-item';
import {EmployeeInfo} from '../models/employee-info';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import {SubjectItem} from '../models/subject-item';
import {ContactDetails} from '../../../Models/User/Contact';
import {Image} from '../../../Models/User/Image';
import {EmployeeTransaction} from '../../finance/Models/EmployeeTransaction';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient) {}

  getList(): Observable<EmployeeListItem[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee/all'
    ).pipe(
      map(employees => employees as EmployeeListItem[])
    );
  }

  getInfo(employeeID): Observable<EmployeeInfo> {
    return this.http.get<EmployeeInfo>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee/' + employeeID + '/info'
    ).pipe(
      map(info => info as EmployeeInfo)
    );
  }

  getUserInfo(userID): Observable<EmployeeInfo> {
    return this.http.get<EmployeeInfo>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee/' + userID + '/userinfo'
    ).pipe(
      map(info => info as EmployeeInfo)
    );
  }

  classrooms(employeeID): Observable<Classroom[]> {
    return this.http.get<Classroom[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee/' + employeeID + '/classrooms'
    ).pipe(
      map(info => info as Classroom[])
    );
  }

  subjects(employeeID): Observable<SubjectItem[]> {
    return this.http.get<SubjectItem[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee/' + employeeID + '/subjects'
    ).pipe(
      map(info => info as SubjectItem[])
    );
  }

  contact(employeeID): Observable<ContactDetails> {
    return this.http.get<ContactDetails>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee/' + employeeID + '/contact'
    ).pipe(
      map(contact => contact as ContactDetails)
    );
  }

  dpImage(employeeID, $resolution): Observable<Image> {
    return this.http.get(GlobalValues.BASE_ENDPOINT_URL +
      '/employee/' + employeeID + '/dp/' + $resolution).pipe(
        map(
          res => res as Image
        )
    );
  }

  getFinancialtransaction(employeeID): Observable<EmployeeTransaction[]> {
    return this.http.get<EmployeeTransaction[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employees/finance/transactions/' + employeeID
    ).pipe(
      map(transactions => transactions as EmployeeTransaction[])
    );
  }

  addEmployee(
    personal_data , contact_data, employment_data, login_data, address_data
  ): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/employee', {
        personal_data: JSON.stringify(personal_data),
        // profile_image,
        contact_data: JSON.stringify(contact_data),
        employment_data: JSON.stringify(employment_data),
        login_data: JSON.stringify(login_data),
        address_data: JSON.stringify(address_data)
      }
    );
  }
}
