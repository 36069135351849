import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Grade} from '../../../Models/Classrooms/Grade';
import {GlobalValues} from '../../../../globals';

@Injectable({
  providedIn: 'root'
})
export class GradeService {

  constructor(private http: HttpClient) { }

  getGrades(): Observable<Grade[]> {
    return this.http.get<Grade[]>(
      GlobalValues.BASE_ENDPOINT_URL +
      "/grades"
    ).pipe(
      map(grades => <Grade[]>grades)
    );
  }

  addGrade(title:string, level: number): Observable<any> {
    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      "/grades", {
        title: title,
        level: level
      }
    ).pipe(
      resp => {
        return resp;
      }
    );
  }

  updateGrade(gradeID: number, title:string, level: number): Observable<any> {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      "/grades/"+gradeID, {
        title: title,
        level: level
      }
    ).pipe( resp => {
      return resp;
    });
  }

  deleteGrade(gradeID: number): Observable<any> {
    return this.http.delete(
      GlobalValues.BASE_ENDPOINT_URL +
      "/grades/"+gradeID
    ).pipe( resp => {
      return resp;
    });
  }

  validateTitle(title: string) {
    if(title.length < 2) { return false; }
    return true;
  }

  validateLevel(level: number) {
    if( level >= 0 && typeof level == 'number') { return true; }
    return false;
  }
}
