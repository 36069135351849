import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SidebarModule } from './after-login/layout/sidebar/sidebar.module';
import { NavbarModule } from './after-login/layout/navbar/navbar.module';
import {FormsModule} from "@angular/forms";

import { AppComponent } from './app.component';
import { MainPanelComponent } from './after-login/contents/main-panel/main-panel.component';
import { ContentHolderComponent } from './after-login/contents/content-holder/content-holder.component';
import { AfterLoginComponent } from './after-login/after-login.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './Guards/auth.guard';
import { HomeModule } from './modules/home/home.module';
import { HttpClientModule } from '@angular/common/http';
import { SchoolModule } from './modules/school/school.module';
import {SessionsModule} from './modules/sessions/sessions.module';
import {EmployeesModule} from './modules/employees/employees.module';
import {ClassroomsModule} from './modules/classrooms/classrooms.module';
import {GradeModule} from './modules/grade/grade.module';
import {SubjectsModule} from './modules/subjects/subjects.module';
import {BaseSubjectModule} from './modules/base-subject/base-subject.module';
import {StudentModule} from './modules/student/student.module';
import {ExamresultsModule} from './modules/examresults/examresults.module';
import {FinanceModule} from './modules/finance/finance.module';
import {StudentAttendanceModule} from "./modules/student-attendance/student-attendance.module";
import {EmployeeAttendanceModule} from "./modules/employee-attendance/employee-attendance.module";
import { HomepageComponent } from './homepage/homepage.component';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";


@NgModule({
  declarations: [
    AppComponent,
    MainPanelComponent,
    ContentHolderComponent,
    AfterLoginComponent,
    LoginComponent,
    HomepageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SidebarModule,
    NavbarModule,
    HomeModule,
    SchoolModule,
    SessionsModule,
    EmployeesModule,
    ClassroomsModule,
    GradeModule,
    SubjectsModule,
    BaseSubjectModule,
    StudentModule,
    ExamresultsModule,
    FinanceModule,
    StudentAttendanceModule,
    EmployeeAttendanceModule
  ],
  providers: [
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
