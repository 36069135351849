import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../Services/account.service';
import {ClassroomService} from '../../../../classrooms/services/classroom.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-classroom-home',
  templateUrl: './classroom-home.component.html',
  styleUrls: ['./classroom-home.component.css']
})
export class ClassroomHomeComponent implements OnInit {

  classroom: any;
  students: any;
  summary: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private classroomService: ClassroomService,
  ) { }

  ngOnInit() {
    Swal.showLoading();
    this.activatedRoute.params.subscribe(routeParams => {
      this.getClassroomDetails(routeParams.classroomID);
      this.getClassSummary(routeParams.classroomID);
    });
  }

  getClassroomDetails(classroomID) {
    this.classroomService.getDetails(classroomID)
      .subscribe(resp => {
        this.classroom = resp;
        this.students = resp.students;
        Swal.close();
      }, error => {
        Swal.fire('Classroom not found.', '', 'error');
        this.router.navigate(['admin/finance/students']);
      });
  }

  getClassSummary(classroomID) {
    this.accountService.classroomSummary(classroomID)
      .subscribe(resp => {
        console.log(resp);
        this.summary = resp;
      });
  }
}
