import { Component, OnInit } from '@angular/core';
import {Grade} from '../../../Models/Classrooms/Grade';
import {Classroom} from '../../../Models/Classrooms/Classroom';
import {GradeService} from '../../grade/services/grade.service';
import {ClassroomService} from '../../classrooms/services/classroom.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-class-room-select',
  templateUrl: './class-room-select.component.html',
  styleUrls: ['./class-room-select.component.css']
})
export class ClassRoomSelectComponent implements OnInit {
  grades: Grade[];
  classrooms: Classroom[];
  mGrade: number;
  mClassroom: number;
  errorMsg: string = null;
  constructor(
    private gradeService: GradeService,
    private classroomService: ClassroomService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getGrades();
    this.currentSessionClassrooms();
  }

  resetClassroomModel() {
    this.mClassroom = null;
  }

  getGrades() {
    this.grades = null;
    this.gradeService.getGrades().subscribe(
      grades => this.grades = grades
    );
  }
  currentSessionClassrooms() {
    return this.classroomService.getCurrent().subscribe(
      classrooms => this.classrooms = classrooms
    );
  }

  searchClassroom() {
    this.errorMsg = null;
    if(!this.mClassroom) {
      this.errorMsg = "Select a classroom to continue.";
      return;
    }
    this.router.navigate(['admin/students/list', this.mClassroom]);
  }

}
