import { Component, OnInit } from '@angular/core';
import {StudentList} from '../Models/StudentList';
import {StudentService} from '../services/student.service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-student-list-classroom',
  templateUrl: './student-list-classroom.component.html',
  styleUrls: ['./student-list-classroom.component.css']
})
export class StudentListClassroomComponent implements OnInit {

  classroomTitle: string = null;
  constructor(
    private studentService: StudentService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(routeParams => {
      this.getStudentsList(routeParams.classroomID);
    });
  }

  studentsList: StudentList[];

  getStudentsList(classroomID) {
    this.classroomTitle = null;
    Swal.showLoading();
    this.studentService.getClassroomStudents(classroomID)
      .subscribe(studentsList => {
        this.studentsList = studentsList;
        this.classroomTitle = studentsList[0].classroom_title ? studentsList[0].classroom_title : null;
        Swal.close();
        },
        error1 => {
        this.classroomTitle = null;
        let errorMessage: string = "";
        if(error1.error.success == false) {
          errorMessage = error1.error.error;
        } else {
          errorMessage = error1.status + ": " + error1.statusText;
        }
        Swal.fire({
          title: errorMessage,
          text: "",
          type: "error"
        });
      });
  }

  getDetails(registrationNumber) {
    this.router.navigate(['admin/students/details', registrationNumber]);
  }
}
