import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoComponent } from './info/info.component';
import {SchoolService} from './services/school.service';
import {SchoolRoutingModule} from './school-routing.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtAppInterceptorService} from '../../Guards/services/jwt-app-interceptor.service';

@NgModule({
  declarations: [InfoComponent],
  imports: [
    CommonModule,
    SchoolRoutingModule,
    SweetAlert2Module.forRoot(),
  ],
  exports: [
    InfoComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAppInterceptorService,
      multi: true
    },
    SchoolService
  ]
})
export class SchoolModule { }
