import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InfoComponent } from './info/info.component';
import { AfterLoginComponent } from '../../after-login/after-login.component';
import { AuthGuard } from '../../Guards/auth.guard';


const schoolRoutes: Routes = [
  {
    'path' : 'admin/school',
    component: AfterLoginComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: InfoComponent }
    ]
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(schoolRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SchoolRoutingModule { }
