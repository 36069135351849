import { Component, OnInit } from '@angular/core';
import {StudentAttendanceService} from "../../services/student-attendance.service";
import {StudentAttendance} from "../../models/StudentAttendance";
import {ActivatedRoute, Router} from "@angular/router";
import {ClassroomService} from "../../../classrooms/services/classroom.service";
import {ClassroomDetails} from "../../../classrooms/classroom-list/ClassroomDetails";
import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: 'app-classroom-attendance-view-single',
  templateUrl: './classroom-attendance-view-single.component.html',
  styleUrls: ['./classroom-attendance-view-single.component.css']
})
export class ClassroomAttendanceViewSingleComponent implements OnInit {

  constructor(
    private studentAttendanceService: StudentAttendanceService,
    private classroomService: ClassroomService,
    private activatedRoute: ActivatedRoute,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private router: Router
  ) { }

  attendanceData: StudentAttendance[];
  classroomID;
  mAttendanceDate: NgbDateStruct;
  classroom: ClassroomDetails;
  attendanceDate;
  ngOnInit() {
    Swal.showLoading();
    this.activatedRoute.paramMap.subscribe(params => {
      this.classroomID = params.get('classroomID');
      this.attendanceDate = params.get('date');
      this.mAttendanceDate = this.ngbDateParserFormatter.parse(this.attendanceDate);
      this.getStudentAttendanceData(this.classroomID, this.attendanceDate);
      this.getClassroomDetails();
    });
  }

  getStudentAttendanceData(classroomID, attendanceDate) {
    this.studentAttendanceService.getClassroomAttendanceByDate(
      classroomID, attendanceDate
    ).subscribe(attendanceData => {
      this.attendanceData = attendanceData;
      if(this.attendanceData && this.classroom) {
        Swal.close();
      }
    });
  }

  getClassroomDetails() {
    this.classroomService.getDetails(this.classroomID)
      .subscribe(resp => {
        this.classroom = resp;
        if(this.attendanceData && this.classroom) {
          Swal.close();
        }
      }, error => {
        console.log("Err: ", error);
      });
  }

  updateAttendance() {
    let newDate = this.ngbDateParserFormatter.format(this.mAttendanceDate);
    if(newDate != this.attendanceDate) {
      Swal.showLoading();
      this.router.navigate(
        ['/admin/attendance/students/classroom',
        this.classroomID, newDate, 'view']
      );
    }
  }

}
