import { Component, OnInit } from '@angular/core';
import {AcademicSession} from '../models/AcademicSession';
import {SessionService} from '../services/session.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  sessionList: AcademicSession[];
  newSessionStatus: boolean = false;

  constructor(private _session: SessionService) { }

  ngOnInit() {
    this.updateSessions();
  }

  updateSessions() {
    this._session.getSessions().subscribe(
      sessions => this.sessionList = sessions
    );
  }

  toggleNewSessionStatus() {
    this.newSessionStatus = !this.newSessionStatus;
  }
}
