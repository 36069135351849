import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GuardianRelation} from '../../../Models/Guardian/GuardianRelation';
import {GlobalValues} from '../../../../globals';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {District} from '../../../Models/User/Profile/Address/District';
import {StudentList} from '../Models/StudentList';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(
    private http: HttpClient
  ) { }

  getGuardianRelations(): Observable<GuardianRelation[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/student/guardian_relations'
    ).pipe(
      map(relations => <GuardianRelation[]> relations)
    );
  }

  getUserByCellNumber(cellNumber): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/student/guardian_details/'+cellNumber
    ).pipe(
      map(user => user)
    );
  }

  addStudent(
    personalData: Object,
    enrollmentData: Object,
    addressData: Object,
    guardianData: Object
    ): Observable<any> {

    return this.http.post(
      GlobalValues.BASE_ENDPOINT_URL +
      '/student/add',
      {
        'personal_data': JSON.stringify(personalData),
        'enrollment_data': JSON.stringify(enrollmentData),
        'address_data': JSON.stringify(addressData),
        'guardian_data': JSON.stringify(guardianData)
      }
    ).pipe(
      map(resp => resp)
    );
  }

  studentDetails(registrationNumber): Observable<any> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/student/details/'+registrationNumber
    ).pipe(
      details => details
    );
  }

  getClassroomStudents(classroomID): Observable<StudentList[]> {
    return this.http.get(
      GlobalValues.BASE_ENDPOINT_URL +
      '/classrooms/students/' + classroomID
    ).pipe(
      map(studentsList => <StudentList[]> studentsList)
    );
  }

  updateInfo(registrationNumber, firstName, middleName, lastName, dateOfBirth, gender): Observable<any> {
    if(gender == 'Male') {
      gender = 'm';
    } else if( gender == 'Female') {
      gender = 'f';
    }

    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/student/info/'+ registrationNumber,
      {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        dob: dateOfBirth,
        gender
      }
    );
  }

  updateEnrollmentDetails(
    registrationNumber, newRegNumber, rollNumber, studentSchoolID
  ): Observable<any> {
    return this.http.put(
      GlobalValues.BASE_ENDPOINT_URL +
      '/student/enrollment/'+ registrationNumber,
      {
        new_registration_number: newRegNumber,
        roll_number: rollNumber,
        student_school_id: studentSchoolID
      }
    );
  }
}
